import React, { useState } from 'react'
import { ActiveArrow, DefaultArrow } from './Svg'

interface IProps {
    title: string
    isOpen: boolean
    onOpen: () => void
    loadContent?: () => void
    children: React.ReactNode
}

export default function DropDownMenu({ title, children, onOpen, isOpen, loadContent }: IProps) {
    const [isLoaded, setIsLoaded] = useState(false)
    return (
        <div>
            <button
                className="flex items-center mb-[16px] rounded-lg text-sm font-medium leading-5"
                onClick={() => {
                    if (!isLoaded && typeof loadContent === 'function') loadContent()
                    setIsLoaded(true)
                    onOpen()
                }}>

                <span className="heading-uppercase heading-font font tracking-tight font-medium tablet:hover:font-bold hover:ease-in hover:duration-200 text-18 text-rocket">
                    {title}
                </span>

                <div className="flex items-center justify-center ml-2 w-[22px]">
                    {isOpen ? (
                        <DefaultArrow width={13} height={8} />
                    ) : (
                        <ActiveArrow width={13} height={8} />
                    )}
                </div>
            </button>

            <div className={`mb-[16px] ${isOpen ? 'transform scale-y-0 h-0' : ''}`}>
                {children}
            </div>
        </div>
    )
}
