import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../common/Button'
import SecondButton from '../common/SecondButton'

interface IProps {
    setShowModal: (value: boolean) => void;
    showModal: boolean
    isFuture: boolean | undefined
    title: string
    onSubmit?: any
    loading: boolean
}

export default function PledgeSuccessModal({ showModal, isFuture, title, setShowModal, onSubmit, loading }: IProps) {
    const [t] = useTranslation('global')

    return (
        showModal && (
            <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                    <div className="bg-white relative px-4 py-10 xs-mobile:w-[500px] w-11/12 rounded-lg"
                        style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                        <h1 className="heading-uppercase heading-font text-22 text-cavolo font-semibold tracking-[-0.02rem] text-center not-heading-font">
                            {title}
                        </h1>

                        {isFuture ? (
                            <div className="flex items-center justify-between mt-5">
                                <Button
                                    loading={loading}
                                    onClick={onSubmit}
                                    style={{ width: '48%', marginTop: 0 }}
                                    className="cursor-pointer h-[48px] font-semibold rounded-md text-14"
                                    text={t('yourImpact.yes')} />

                                <SecondButton
                                    text={t('yourImpact.no')}
                                    onClick={() => setShowModal(false)}
                                    className="w-[48%] h-[48px] font-semibold bg-mint border-2 border-rocket rounded-md text-14 text-rocket" />
                            </div>
                        ) : (
                            <div className="flex justify-center mt-5">
                                <Button
                                    onClick={() => setShowModal(false)}
                                    style={{ width: '40%' }}
                                    className="cursor-pointer h-[48px] font-semibold rounded-md text-14"
                                    text={t('yourImpact.ok')} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    )
}
