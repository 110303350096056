import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import { Context } from '../../components/common/BaseContext'
import Button from '../../components/common/Button'
import SelectCalendar from '../../components/common/SelectCalendar'
import { travelDate } from '../../utils/date'
import { handleCommuteStartEnd } from '../../state/travel/actions'
import { commuteStartEndList } from '../../state/travel/selectors'
import { getUser } from '../../state/user/selectors'

export default function LogTravelDate() {
    const dispatch = useDispatch()
    const today = new Date()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { commuteStartEnd } = useSelector(commuteStartEndList)
    const [selectedDays, setSelectedDays] = React.useState<string[]>([])
    const [year, setYear] = useState(today.getFullYear())
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const [t] = useTranslation('global')
    const { isEmbedded } = useContext(Context)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')
    const location = useLocation()
    const impact = location?.state?.impact
    const startDate = location?.state.startDate
    const dates = location?.state.dates
    const ids = location?.state.ids
    const countOfPeople = location?.state.countOfPeople
    const isSwitched = location?.state.isSwitched
    const startPlace = location?.state.startPlace
    const endPlace = location?.state.endPlace
    const selectedValue = location?.state.selectedValue
    const isChecked = location?.state.isChecked

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const [month, setMonth] = useState(() => {
        const gameDate = outboundData?.gameDate?.[0]
        return gameDate ? new Date(gameDate).getMonth() + 1 : today.getMonth() + 1
    })
    const dateValues = useMemo(() => location?.state?.dateValues || [], [location.state])

    const handleSelectDay = (day: number) => {
        const selectedDate = new Date(year, month - 1, day)
        const formattedDate = travelDate(selectedDate)

        setSelectedDays((prevSelected) => (prevSelected.includes(formattedDate)
            ? prevSelected.filter((d) => d !== formattedDate)
            : [...prevSelected, formattedDate]))
    }

    useEffect(() => {
        if (dateValues.length) {
            const formattedDateValues = dateValues.map((dateString: any) => {
                const dateObj = new Date(dateString.item.date)
                return travelDate(dateObj)
            })
            setSelectedDays(formattedDateValues)
        }
    }, [dateValues])

    const handlePrevMonth = () => {
        if (month === 1) {
            setMonth(12)
            setYear(year - 1)
        } else {
            setMonth(month - 1)
        }
    }

    const handleNextMonth = () => {
        if (month === 12) {
            setMonth(1)
            setYear(year + 1)
        } else {
            setMonth(month + 1)
        }
    }

    const minDate = outboundData.date ? new Date(Math.min(...outboundData.date.map((date: string) => new Date(date)))).toISOString().split('T')[0] : 0

    const onSubmit = () => {
        if (isChecked && user?.company?.autoForwardIfSingleEvent) {
            const updatedTravelData = {
                ...outboundData,
                date: selectedDays || outboundData.date || [],
            }

            localStorage.setItem('travelData', JSON.stringify(updatedTravelData))

            const storedDataString = localStorage.getItem('travelData')
            const storedData = storedDataString ? JSON.parse(storedDataString) : {}

            dispatch(
                handleCommuteStartEnd(
                    storedData,
                    storedData?.backendCommuteId || commuteStartEnd?.id,
                    startPlace,
                    endPlace,
                    selectedValue ? Number(selectedValue) : undefined,
                    () => {
                        navigate('/log/overnight-stay', {
                            state: {
                                impact,
                                prevUrl: location?.state?.prevUrl,
                                travelName: startPlace?.name,
                            },
                        })
                    },
                ),
            )

            return
        }

        localStorage.setItem('travelData', JSON.stringify({
            date: selectedDays || [travelDate(startDate)],
            gameDate: dates || [],
            gameIds: ids || [],
            legs: [],
            gameData: dateValues || [],
            traveledWithOthersWhoDidntLogCount: countOfPeople || 0,
        }))

        navigate('/log/track-travel-start-end', { state: { isSwitched, prevUrl: location?.state?.prevUrl } })
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false} travel>
            <div className="w-full">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div className="flex flex-col items-center mobile:pt-[54px] pt-5 mobile:px-[42px] px-[16px] h-screen">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        <h1 className="heading-uppercase heading-font text-22 tracking-tight font-semibold text-cavolo">
                            {t('log.log-a-journey')}
                        </h1>

                        <h1 className="text-16 tracking-tight font-medium not-heading-font text-cavolo my-6">
                            {new Date(minDate) <= new Date(travelDate(today)) ? (
                                t('log.FUTURE|what-are-the-dates-of-your-trip')
                            ) : (
                                t('log.what-are-the-dates-of-your-trip')
                            )}
                        </h1>

                        <SelectCalendar
                            year={year}
                            month={month}
                            selectedDays={selectedDays}
                            onSelect={handleSelectDay}
                            onPrevMonth={handlePrevMonth}
                            onNextMonth={handleNextMonth}
                        />
                    </div>
                </div>

                <div
                    className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                    style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                    <div
                        className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                        <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                        <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                            <Button
                                onClick={onSubmit}
                                className="absolute bottom-8 duration-100 not-heading-font"
                                text={t('global.continue')} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
