import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DismissIcon, SavedIconActive, SavedIconDefault } from '../common/Svg'
import Skeleton from '../common/Skeleton'
import SponsoredByBlock from '../SponsoredByBlock'
import ArticleDetailQuizz from './ArticleDetailQuizz'
import PointModal from '../common/PointModal'
import { Context } from '../common/BaseContext'
import { articleBookMark, fetchArticleById } from '../../state/articles/actions'
import { getDatesLocalization } from '../../utils/date'
import { articles } from '../../state/articles/selectors'
import { getUser } from '../../state/user/selectors'

interface IProps {
  setShowModal: (value: number | undefined) => void;
  articleId: number | undefined
}

export default function ArticleModal({ setShowModal, articleId }: IProps) {
    const dispatch = useDispatch()
    const { articleById } = useSelector(articles)
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const [showModalPoint, setShowModalPoint] = useState<boolean | undefined>(false)
    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const { screenWidth } = useContext(Context)

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const article = articleId === articleById?.id ? articleById : null

    useEffect(() => {
        const fetchData = async () => {
            if (article?.createdAt) {
                const formatted = await getDatesLocalization(article?.createdAt, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [article?.createdAt])

    let extractedNumber: number | null = null

    if (typeof article?.getReadTime === 'string') {
        const matchResult = article?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    const onPressBookmark = async (id: number | undefined) => {
        await dispatch(articleBookMark(id))
    }

    useEffect(() => {
        if (articleId) {
            dispatch(fetchArticleById(Number(articleId)))
        }
        // eslint-disable-next-line
    }, [articleId])

    return (
        <div>
            {/* <style>{'html, body {height: 100%; overflow: hidden;}'}</style> */}

            <div className="fixed bg-white inset-0 z-[1000000] overflow-y-auto">
                <div onClick={() => setShowModal(0)} className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                <div className="relative flex items-center justify-center">
                    <div className="relative w-full h-full bg-white rounded-lg">
                        <div className="fixed top-0 left-0 w-full bg-white shadow-md flex items-center justify-between py-4 px-6 z-10">
                            <div className="w-full flex justify-end cursor-pointer" onClick={() => setShowModal(0)}>
                                <DismissIcon width={20} height={20} />
                            </div>
                        </div>

                        <div className="h-screen pt-12 pb-[100px] overflow-y-scroll">
                            <div className="text-black min-h-screen w-full">
                                {screenWidth < 912 ? (
                                    <div className="flex flex-col items-center relative">
                                        {loading && <Skeleton className="object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]" />}
                                        <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]`} src={article?.image} alt="any" />

                                        <div className="mt-[-10%] tablet911:w-[476px] w-11/12 flex flex-col tablet911:relative bg-pistachio items-center text-center tablet911:h-[540px]">

                                            <button onClick={() => setShowModal(0)}
                                                className="text-rocket mt-[32px] tablet911:absolute tablet911:mt-[70px] tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                                {t('navbar.Home')}
                                            </button>

                                            <div className="flex px-5 mobile:mt-10 mt-[32px] tablet911:absolute medium:mt-[182px] tablet:mt-[223px] medium:mx-8 flex-col justify-between">
                                                <h5 className="text-14 hidden tablet911:block font-light my-2 text-grey01">
                                                    {user?.company?.isDateStampShown && formattedDate} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                                                </h5>

                                                <h1 className="font-medium font tracking-tight text-black mobile:text-32 text-26">
                                                    {loading ? (
                                                        <div className="flex flex-col items-center">
                                                            <Skeleton className="medium:w-[280%] w-full h-[18px] rounded-sm mt-3" />
                                                            <Skeleton className="medium:w-[170%] w-full h-[18px] rounded-sm mt-4" />
                                                        </div>
                                                    ) : (
                                                        article?.title
                                                    )}

                                                </h1>
                                            </div>

                                            <button onClick={() => onPressBookmark(article?.id)} className="rounded-full mobile:mb-[43.5px] mb-[28.5px] mobile:mt-[36.5px] mt-[28.5px] tablet911:absolute tablet911:mt-[470px] flex justify-center items-center bg-black w-[28px] h-[28px]">
                                                {article?.isBookmark ? (
                                                    <SavedIconActive width={12} height={15} />
                                                ) : (
                                                    <SavedIconDefault width={12} height={15} />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex relative items-center tablet911:flex-row flex-col-reverse">
                                        <div className="tablet911:w-[476px] w-11/12 flex -bottom-28 absolute tablet911:bottom-0 h-[280px] flex-col tablet911:relative bg-pistachio items-center text-center tablet911:h-[540px]">
                                            <button onClick={() => setShowModal(0)} className="text-rocket mt-9 tablet911:absolute tablet911:mt-[70px] tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                                {t('navbar.Home')}
                                            </button>

                                            <div className="flex px-5 mt-10 tablet911:absolute medium:mt-[182px] tablet:mt-[223px] medium:mx-8 flex-col justify-between">
                                                <h5 className="text-14 hidden tablet911:block font-light my-2 text-grey01">
                                                    {user?.company?.isDateStampShown && formattedDate} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                                                </h5>

                                                <h1 className="font-medium font tracking-tight text-black mobile:text-32 text-26">
                                                    {loading ? (
                                                        <div className="flex flex-col items-center">
                                                            <Skeleton className="medium:w-[280%] w-full h-[18px] rounded-sm mt-3" />
                                                            <Skeleton className="medium:w-[170%] w-full h-[18px] rounded-sm mt-4" />
                                                        </div>
                                                    ) : (
                                                        article?.title
                                                    )}

                                                </h1>
                                            </div>

                                            <button onClick={() => onPressBookmark(article?.id)} className="rounded-full mt-5 tablet911:absolute tablet911:mt-[470px] flex justify-center items-center m-3 bg-black w-[28px] h-[28px]">
                                                {article?.isBookmark ? (
                                                    <SavedIconActive width={12} height={15} />
                                                ) : (
                                                    <SavedIconDefault width={12} height={15} />
                                                )}
                                            </button>
                                        </div>

                                        {loading && <Skeleton className="object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]" />}
                                        <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]`} src={article?.image} alt="any" />
                                    </div>
                                )}

                                {article?.sponsorshipBlock && <div className="mb-3 mobile:hidden w-11/12" style={{ margin: '0 auto' }}><SponsoredByBlock item={article?.sponsorshipBlock} /></div>}

                                <div className={`${screenWidth < 1440 && 'flex justify-center'} medium:mt-8 mobile:mt-[32px] mt-[16px]`}>
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <h1 className="w-[764px] rich-text laptop:ml-[476px] tablet:mx-[74px] mx-[16px]" dangerouslySetInnerHTML={{ __html: article?.body ?? '' }} />
                                </div>

                                <div className={`${screenWidth < 1440 && 'flex items-center justify-center'}`}>
                                    <div className="w-[764px] laptop:ml-[476px] tablet:mx-[74px] mx-[16px] mt-[84px]">
                                        <div className="w-full">
                                            {!article?.quizzCompleted && article?.questions && article?.questions.length
                                                ? <ArticleDetailQuizz article={articleById} setShowModalPoint={setShowModalPoint} /> : ''}
                                        </div>
                                    </div>
                                </div>

                                <PointModal
                                    isShown={showModalPoint}
                                    onClose={() => setShowModalPoint(false)}
                                    point={5}
                                    showModal
                                    description={t('article.just-for-answering-the-quiz-correctly,-you’ve-earned')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
