import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { Radio } from '../common/Radio'
import Input from '../common/Input'
import SecondButton from '../common/SecondButton'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { ISurvey } from '../../types/data'
import SurveyDetailHeader from './SurveyDetailHeader'
import { legTransportList, surveyList } from '../../state/travel/selectors'
import PointModal from '../common/PointModal'
import { postSurvey } from '../../state/travel/actions'
import { Context } from '../common/BaseContext'
import SurveyCompleteModal from './SurveyCompleteModal'
import { fetchUser } from '../../state/user/actions'
import { CLEAR_SURVEY_DETAIL } from '../../state/travel/consts'

interface IPops{
    survey: boolean
    setShowModalPoint?: (value: boolean) => void
    showModalPoint?: boolean
    surveyDetail: ISurvey
}

export default function SurveyBody({ survey, surveyDetail: surveyData, setShowModalPoint, showModalPoint }: IPops) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const location = useLocation()
    const { user } = useSelector(getUser)
    const { setShowSurveyModal } = useContext(Context)
    const { surveyDetail } = useSelector(surveyList)
    const { travelCommuteDetail } = useSelector(legTransportList)
    const [t] = useTranslation('global')
    const [selectedAnswers, setSelectedAnswers] = useState<number[]>([])
    const [activeSurvey, setActiveSurvey] = useState<any | ISurvey>(null)
    const [activeSurveyIndex, setActiveSurveyIndex] = useState<number>(0)
    const [showOtherOption, setShowOtherOption] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string | number>('')

    useEffect(() => {
        if (surveyData && surveyData?.questions && !activeSurvey) setActiveSurvey(surveyData?.questions[0])
    }, [activeSurvey, surveyData])

    const handleChoiceAnswer = useCallback(
        (value: string | number) => {
            const answerId = Number(value)

            setSelectedAnswers((prevSelectedAnswers) => {
                if (prevSelectedAnswers.includes(answerId)) {
                    return prevSelectedAnswers.filter((id) => id !== answerId)
                }

                return activeSurvey?.answerType === 'multiple_choice'
                    ? [...prevSelectedAnswers, answerId]
                    : [answerId]
            })

            if (showOtherOption) {
                setShowOtherOption(false)
            }
        },
        [activeSurvey, showOtherOption],
    )

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    // const onChangeOtherOption = () => {
    //     if (selectedAnswers?.length >= 1) {
    //         setSelectedAnswers([])
    //         setShowOtherOption(true)
    //     } else {
    //         setShowOtherOption(!showOtherOption)
    //     }
    // }

    const onChangeOtherOption = () => {
        if (activeSurvey?.answerType === 'multiple_choice') {
            setShowOtherOption((prevState) => !prevState)
        } else if (selectedAnswers?.length >= 1) {
            setSelectedAnswers([])
            setShowOtherOption(true)
        } else {
            setShowOtherOption(!showOtherOption)
        }
    }

    const onSubmit = (value: {other: string}) => {
        const newTotalAnswer = {
            question: activeSurvey?.id || 0,
            ...(selectedAnswers?.length ? { answers: selectedAnswers } : {}),
            otherAnswer: String(value?.other || inputValue || ''),
            submit: activeSurveyIndex + 1 === surveyData?.questions?.length,
            userAnswerGroup: surveyDetail?.userAnswerGroup,
        }

        setSelectedAnswers([])
        setInputValue('')
        setShowOtherOption(false)

        dispatch(postSurvey(newTotalAnswer, () => {
            setActiveSurvey(surveyData?.questions[activeSurveyIndex + 1])
            if (activeSurveyIndex + 1 !== surveyData?.questions?.length) {
                setActiveSurveyIndex(activeSurveyIndex + 1)
            } else {
                setActiveSurveyIndex(0)
                dispatch({ type: CLEAR_SURVEY_DETAIL })
                dispatch(fetchUser)
            }
        }))

        if (activeSurveyIndex + 1 === surveyData?.questions?.length && location.pathname === '/') {
            setShowModalPoint?.(true)
            setActiveSurveyIndex(0)

            if (surveyData?.display === 'full' && surveyData?.completeOncePerUser) {
                setShowSurveyModal(0)
                navigate('/')
            }
        }

        if (location.pathname !== '/' && activeSurveyIndex + 1 === surveyData?.questions?.length) {
            const hasMultiDaySections = travelCommuteDetail?.multiDaySections?.length > 1
            const dataCollectionType = user?.company?.singleUseDataCollection

            if (hasMultiDaySections) {
                navigate(dataCollectionType === 'prize_draw' ? '/log/prize-draw' : '/')
            } else {
                navigate('/log/your-impact')
            }
        }
    }

    return (
        showModalPoint && surveyData?.display === 'widget' ? (
            <PointModal
                survey
                isShown={showModalPoint}
                onClose={() => {
                    if (surveyData?.display === 'full') {
                        setShowSurveyModal(0)
                        navigate('/')
                    }
                    setShowModalPoint?.(false)
                }}
                point={surveyData?.pointsForCompetition}
                description={surveyData?.completionMessage} />
        ) : (
            <Formik key={activeSurveyIndex} initialValues={{ other: '' }} onSubmit={onSubmit}>
                <Form>
                    <style>
                        {`
                            .radio-style input[type='radio'] {
                                outline: none;
                                appearance: none;
                                width: 24px;
                                height: 24px;
                                padding: 3px;
                            }

                            .btnd:active,
                            .btnd:focus,
                            .btnd:focus:active {
                                background-image: none;
                                outline: 0;
                                box-shadow: none;
                            }

                            .radio-style input[type="radio"]:checked {
                                width: 24px;
                                height: 24px;
                                background: ${(whiteLabeling as any)?.rocket} content-box;
                            }
                    `}
                    </style>

                    {!survey && (
                        <h1 className="font text-18 text-black font-medium">
                            {activeSurvey?.question}
                        </h1>
                    )}

                    {survey && (
                        <SurveyDetailHeader surveyDetail={surveyData} activeQuestionIndex={activeSurveyIndex}
                            totalQuestionsCount={surveyData?.questions?.length || 0}
                            card={false}
                            survey={survey}
                        />
                    )}

                    <div className={`max-w-[764px] w-full ${survey && 'mt-[54px]'}`}>
                        <div className="mb-4">
                            {survey && (
                                <h1 className="font text-18 text-black font-medium">
                                    {activeSurvey?.question}
                                </h1>
                            )}

                            <h1 className="text-12 text-grey01 font-normal">
                                {activeSurvey?.answerType === 'single_choice' && t('surveys.select-one-option')}
                                {activeSurvey?.answerType === 'multiple_choice' && t('surveys.select-all-that-apply')}
                            </h1>
                        </div>

                        {!survey && (
                            <div className="mb-4">
                                <SurveyDetailHeader surveyDetail={surveyData} activeQuestionIndex={activeSurveyIndex}
                                    totalQuestionsCount={surveyData?.questions?.length || 0}
                                    card={false}
                                    survey={survey}
                                />
                            </div>
                        )}

                        {(activeSurvey?.answerType === 'single_choice' || activeSurvey?.answerType === 'multiple_choice') && (
                            activeSurvey?.answers?.map((item: any) => (
                                <Radio
                                    readOnly
                                    containerStyles={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}
                                    containerClassName="bg-white p-4 rounded-lg mb-4"
                                    inputClassName="mb-0"
                                    labelClassName="mb-0 tracking-[-0.01rem] leading-[150%] text-grey01 font-medium w-full"
                                    key={item?.id}
                                    label={item?.answer}
                                    value={item?.id}
                                    checked={selectedAnswers.includes(item.id)}
                                    onChange={handleChoiceAnswer}
                                />
                            ))
                        )}

                        {activeSurvey?.answerType === 'short_text' && (
                            <Input
                                placeholder={t('surveys.short-text')}
                                value={inputValue}
                                name="short"
                                type="text"
                                onChange={handleInputChange} />
                        )}

                        {activeSurvey?.answerType === 'long_text' && (
                            <Input
                                placeholder={t('surveys.long-text')}
                                value={inputValue}
                                type="textarea"
                                name="long"
                                className="h-32"
                                component="textarea"
                                onChange={handleInputChange}
                            />
                        )}

                        {activeSurvey?.answerType === 'number' && (
                            <Input
                                value={inputValue}
                                placeholder={t('surveys.type-a-number')}
                                name="number"
                                type="number"
                                onChange={handleInputChange} />
                        )}

                        {activeSurvey?.showOtherOption && (
                            <div>
                                <div style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }} onClick={onChangeOtherOption}
                                    className="flex items-center radio-style bg-white p-4 rounded-lg mb-4 cursor-pointer">
                                    <input
                                        readOnly
                                        type="radio"
                                        name="otherOption"
                                        checked={showOtherOption}
                                        className="cursor-pointer appearance-none w-6 h-6 border-rocket transition duration-300 ease-in-out"
                                    />
                                    <span className="ml-3 tracking-[-0.01rem] leading-[150%] text-grey01 font-medium w-full">{t('surveys.other')}</span>
                                </div>

                                <AnimatePresence>
                                    {showOtherOption && activeSurvey?.showOtherOption && (
                                        <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Input
                                                onChange={handleInputChange}
                                                name="other"
                                                type="text"
                                                value={inputValue} />
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        )}
                    </div>

                    {!survey ? (
                        <SecondButton
                            disabled={
                                activeSurvey?.showOtherOption && showOtherOption
                                    ? activeSurvey?.isMandatory ? !inputValue : false
                                    : activeSurvey?.isMandatory ? !inputValue && selectedAnswers.length === 0 && !showOtherOption : false
                            }
                            text={t('surveys.submit')}
                            className="mt-2.5 w-full h-[48px] font-medium bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                        />
                    ) : (
                        <div className="fixed bottom-0 h-[120px] bg-pistachio w-full duration-100"
                            style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)', left: 0 }}>
                            <div
                                className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                                <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                                <div className="mobile:ml-8 mobile:w-[180px] relative">
                                    <SecondButton
                                        disabled={
                                            activeSurvey?.showOtherOption && showOtherOption
                                                ? activeSurvey?.isMandatory ? !inputValue : false
                                                : activeSurvey?.isMandatory ? !inputValue && selectedAnswers.length === 0 && !showOtherOption : false
                                        }
                                        text={t('surveys.submit')}
                                        className="absolute bottom-8 duration-100 not-heading-font mt-2.5 w-full h-[48px] font-medium bg-rocket rounded-md text-14 text-pistachio"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {showModalPoint && surveyData?.display === 'full' && (
                        <SurveyCompleteModal
                            isShown={showModalPoint}
                            setShowModalPoint={setShowModalPoint}
                        />
                    )}
                </Form>
            </Formik>
        )
    )
}
