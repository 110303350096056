import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import GoogleAutoComplete from '../../components/GoogleAutoComplete'
import bedLight from '../../static/bedLight.png'
import bedDark from '../../static/bedDark.png'
import stadiumDark from '../../static/stadiumDark.png'
import stadiumLight from '../../static/stadiumLight.png'
import Button from '../../components/common/Button'
import { Radio } from '../../components/common/Radio'
import { fetchTravelAccommodation } from '../../state/travel/actions'
import { commuteStartEndList } from '../../state/travel/selectors'
import { Colors } from '../../themes'
import { travelDate } from '../../utils/date'

export default function OvernightStay() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const today = new Date()
    const { isEmbedded } = useContext(Context)
    const [t] = useTranslation('global')
    const { sponsorshipBlocks } = useSelector(getHomePageLists)

    const buttonRef = useRef<any>()
    const endRef = useRef<any>()

    const [didntStayOvernight, setDidntStayOvernight] = useState<boolean>(false)

    const [accommodationPlace, setAccommodationPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const [selectedValue, setSelectedValue] = useState<string | number | undefined>('')

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const minDate = outboundData.date.reduce((a: string, b: string) => (new Date(b) < new Date(a) ? b : a)) // new Date(Math.min(...outboundData.date.map((date: string) => new Date(date)))).toISOString().split('T')[0]
    const firstGameDate = outboundData.gameDate.reduce((a: string, b: string) => (new Date(b) < new Date(a) ? b : a))

    const [showSwitch, setShowSwitch] = useState<number>(location?.state?.isSwitched && outboundData?.gameData.length === 1 && (new Date(minDate) < new Date(firstGameDate)) ? 1 : 0)
    const { commuteStartEnd, loading } = useSelector(commuteStartEndList)

    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const switchData = [
        {
            id: 2,
            name: t('overnightStay.event'),
            iconActive: <img className="w-[59px] h-[59px]" src={stadiumLight} alt="" />,
            iconDefault: <img className="w-[59px] h-[59px]" src={stadiumDark} alt="" />,
            value: 'event',
        },
        {
            id: 1,
            name: t('overnightStay.accommodation'),
            iconActive: <img className="w-[59px] h-[35px]" src={bedLight} alt="" />,
            iconDefault: <img className="w-[59px] h-[35px]" src={bedDark} alt="" />,
            value: 'accommodation',
        },
    ]

    const handleRadioChange = (value: string | number) => {
        setSelectedValue((prevValue) => (prevValue === value ? undefined : value))
        setDidntStayOvernight((prevState) => !prevState)
    }

    const onSubmit = () => {
        if (didntStayOvernight) {
            navigate('/log/track-travel-modality', { state: location?.state })
        } else {
            dispatch(fetchTravelAccommodation({
                commute: outboundData.backendCommuteId || commuteStartEnd.id,
                placeId: accommodationPlace.placeId,
                placeName: accommodationPlace.name,
                placeLon: accommodationPlace.lon,
                placeLat: accommodationPlace.lat,
                endLocation: switchData[showSwitch].value,
            }, () => {
                navigate('/log/track-travel-modality', { state: location?.state })
            }))
        }
    }

    useEffect(() => {
        if ((accommodationPlace?.placeId && (new Date(minDate) < new Date(firstGameDate))) || (didntStayOvernight && (new Date(minDate) < new Date(firstGameDate)))) {
            onSubmit()
        }
        // eslint-disable-next-line
    }, [accommodationPlace, didntStayOvernight, firstGameDate, minDate])

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false}>
            <div className="w-full">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div className="flex flex-col items-center mobile:pt-[54px] pt-5 mobile:px-[42px] px-[16px] h-screen">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        <h1 className="heading-uppercase heading-font text-22 font tracking-tight font-semibold text-cavolo mt-2">
                            {t('overnightStay.overnight-stay')}
                        </h1>

                        <h1 className="font-medium not-heading-font text-cavolo text-16 mt-4 mb-2.5">
                            {travelDate(today) >= minDate ? (
                                t('overnightStay.where-did-you-stay-overnight')
                            ) : (
                                t('overnightStay.FUTURE|where-did-you-stay-overnight')
                            )}
                        </h1>

                        <GoogleAutoComplete onChange={() => { buttonRef.current.focus() }} selectRef={endRef} placeholder={t('log.start-typing')} defaultValue={accommodationPlace} setPlace={setAccommodationPlace} />

                        {!(new Date(minDate) < new Date(firstGameDate)) && (
                            <div>
                                <h1 className="mt-4 text-18 text-cavolo not-heading-font font-medium">
                                    {travelDate(today) >= minDate ? (
                                        t('overnightStay.event-description-after')
                                    ) : (
                                        t('overnightStay.FUTURE|event-description-after')
                                    )}
                                </h1>

                                <div className="flex mt-6">
                                    {switchData.map((item, index) => (
                                        <div
                                            key={item.id}
                                            onClick={() => setShowSwitch(index)}
                                            className={`flex items-center justify-center h-[131px] w-[156px] mr-6 rounded-[10px] cursor-pointer shadow-[2px_2px_0_0_rgba(0,0,0,0.15)] transition-transform duration-300 ease-in-out ${showSwitch === index ? 'bg-cavolo scale-105' : 'bg-pistachio border-2 border-spinach scale-100'}`}>
                                            <div className="flex flex-col items-center">
                                                <div
                                                    className="w-[59px] h-[59px] flex items-center justify-center transition-transform duration-300 ease-in-out">
                                                    {showSwitch === index ? item.iconActive : item.iconDefault}
                                                </div>
                                                <h1 className={`mt-2.5 text-16 font-normal not-heading-font tracking-[-0.01em] transition-colors duration-300 ease-in-out ${showSwitch === index ? 'text-white px-[9px]' : 'text-cavolo px-1'}`}>
                                                    {item.name}
                                                </h1>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="mt-6">
                            <Radio
                                label={travelDate(today) >= minDate ? t('overnightStay.i-didn\'t-stay-away-overnight') : t('overnightStay.FUTURE|i-didn\'t-stay-away-overnight')}
                                value={1}
                                checked={selectedValue === 1}
                                onChange={handleRadioChange}
                                inputClassName="mb-4 tablet:mb-[20px]"
                                labelClassName="font-normal text-grey01 text-14 mb-4 tablet:mb-[20px] cursor-pointer"
                                checkBox
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                    style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                    <div
                        className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                        <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                        <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                            <Button
                                buttonRef={(ref: any) => {
                                    buttonRef.current = ref
                                }}
                                loading={loading}
                                disabled={!accommodationPlace.placeId && !didntStayOvernight}
                                onClick={onSubmit}
                                style={!accommodationPlace.placeId && !didntStayOvernight ? disabledStyles : undefined}
                                className="absolute bottom-8 duration-100 not-heading-font"
                                text={t('global.continue')} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
