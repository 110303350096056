import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ProgressBar from '../ProgressBar'
import { getPrizeDraw } from '../../state/prize/selectors'

export default function MyProgress() {
    const navigate = useNavigate()
    const { prizeDraw } = useSelector(getPrizeDraw)
    const [t] = useTranslation('global')

    return (
        prizeDraw?.pointsTargets === undefined || prizeDraw?.pointsTargets.length === 0 ? null : (
            <div className="w-full items-center flex flex-col max-mobile:px-4">
                <div className="w-full">
                    <div className="flex items-center justify-between">
                        <span className="heading-uppercase heading-font font-medium tracking-tight text-18 text-black heading-font">
                            {t('home.my-progress')}
                        </span>

                        <p className="not-heading-font text-14 underline text-rocket cursor-pointer" onClick={() => navigate('/me/progress')}>
                            {t('home.more-information')}
                        </p>
                    </div>

                    <ProgressBar />

                    <p className="mt-10 mb-5 text-grey01 text-14 pointer">{t('home.get-tickets-into-the-prize-draw-by')} <span className="text-rocket font-semibold not-heading-font underline cursor-pointer" onClick={() => navigate('/me/earn-points')}>{t('home.earning-points')}</span></p>
                </div>
            </div>
        )
    )
}
