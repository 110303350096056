import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SurveyBody from '../components/survey/SurveyBody'
import QuizQuitOverlayModal from '../components/quiz/QuizQuitOverlayModal'
import { getUser } from '../state/user/selectors'
import SurveyDetailHeader from '../components/survey/SurveyDetailHeader'
import { getHomePageLists } from '../state/home/selectors'
import SponsoredByBlock from '../components/SponsoredByBlock'
import Layout from '../components/layout/Layout'
import { fetchSurveys } from '../state/travel/actions'
import { surveyList } from '../state/travel/selectors'

export default function Survey() {
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { surveyId } = useParams()
    const dispatch = useDispatch()
    const [t] = useTranslation('global')
    const [showSurveyQuitOverlayModal, setShowSurveyQuitOverlayModal] = useState(false)
    const { surveyById } = useSelector(surveyList)

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(fetchSurveys(surveyId || 0))
        // eslint-disable-next-line
    }, [])

    if (!surveyById?.id) {
        return (
            <div className="bg-pistachio min-h-screen p-4 heading-uppercase heading-font text-22 tracking-tight font-semibold text-cavolo">
                {t('surveys.survey-not-found')}
            </div>
        )
    }

    return (
        <Layout showFooter={false} maxWidth={false}>
            <div className="w-full bg-pistachio min-h-screen overflow-y-scroll pb-[250px]">
                {sponsoredBlock && <div className="mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                {(!surveyById?.id) && (
                    <div className="p-4 heading-uppercase heading-font text-22 tracking-tight font-semibold text-cavolo">
                        {t('surveys.survey-not-found')}
                    </div>
                )}

                {!surveyById?.id && (
                    <SurveyDetailHeader surveyDetail={surveyById} activeQuestionIndex={0}
                        totalQuestionsCount={surveyById?.questions?.length || 0}
                        card
                    />
                )}

                <div className="w-full flex justify-center mobile:px-[42px] px-[16px]">
                    <div className="max-w-[764px] w-full">
                        <SurveyBody surveyDetail={surveyById} survey />
                    </div>
                </div>

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('home.quit-text-if-gamification-is-enabled') : t('home.quit-text-if-gamification-is-disabled')}
                    isShown={showSurveyQuitOverlayModal}
                    onClose={() => navigate('/')}
                    onContinue={() => setShowSurveyQuitOverlayModal(false)} />
            </div>
        </Layout>
    )
}
