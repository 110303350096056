import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { fetchPerksBrand,
    fetchPerksByType,
    fetchPerksFilterTag,
    fetchPerksHomePage,
    fetchSearchCollectionList } from '../../state/perks/actions'
import { FilterIcon } from '../../components/common/Svg'
import PerkByTypeItem from '../../components/common/PerkByTypeItem'
import AllBrandsItem from '../../components/common/AllBrandsItem'
import PerkTags from '../../components/common/PerkTags'
import SearchInput from '../../components/common/SearchInput'
import PerkWeRecommendCard from '../../components/perk/PerkWeRecommendCard'
import { getPerkLists } from '../../state/perks/selectors'
import PerkOfTheDay from '../../components/perk/PerkOfTheDay'
import PerkCard from '../../components/perk/PerkCard'
import PerkOfTheMonth from '../../components/perk/PerkOfTheMonth'
import RecentSearches from '../../components/RecentSearches'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'

const dataSlot = [undefined, undefined, undefined, undefined, undefined, undefined]

export default function Perks() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { perksHomePage, perksByType, recentSearches, searchCollection } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)
    const [t] = useTranslation('global')

    const mainColumnRef = useRef<any>()

    useEffect(() => {
        if (mainColumnRef) {
            mainColumnRef.current.focus()
        }
    }, [mainColumnRef])

    useEffect(() => {
        const firstButton = document.querySelector('.perks-homepage button') as HTMLButtonElement

        if (firstButton) {
            firstButton.focus()
            firstButton.style.outline = 'none'
        }

        const handleKeyDown = (event: any) => {
            if (event.key === 'Tab') {
                firstButton.style.removeProperty('outline')
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [perksHomePage])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Swaps' })
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchPerksHomePage)
        dispatch(fetchPerksByType)
        dispatch(fetchSearchCollectionList)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (perksByType.length === 0) dispatch(fetchPerksByType)
        // eslint-disable-next-line
    }, [])

    const data = [
        { id: 1, title: t('lower.swaps-by-type'), content: <PerkByTypeItem />, loadContent: () => dispatch(fetchPerksByType) },
        { id: 2, title: t('lower.all-brands'), content: <AllBrandsItem />, loadContent: () => dispatch(fetchPerksBrand) },
        { id: 3, title: t('lower.tags'), content: <PerkTags />, loadContent: () => dispatch(fetchPerksFilterTag) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('lower.swaps'),
    }

    /** ** Navigation view all *** */
    const navigateViewAll = (slot: string) => {
        navigate(`/lower/swaps-section/${slot}/view-all`)
    }

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Swaps')

    return (
        <Layout leftSidebarData={leftSidebarData}>
            {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div className="mobile:mx-10 mx-4 mobile:mb-[120px] mb-[80px] medium:mt-[52px] mt-[32px]">
                <button className="w-0 h-0 p-0 m-0 absolute" ref={(ref: any) => {
                    mainColumnRef.current = ref
                }} />

                <h1 className="heading-uppercase heading-font medium:block hidden tracking-tight font-medium text-26 mobile:text-32 tablet:text-36 text-black mb-6">
                    {t('account.Swaps')}
                </h1>

                <SearchInput
                    smallWidthUntil="medium"
                    action="perks"
                    placeholder={t('lower.find-a-great-swap')}
                    onFocus={handleFocus} />

                {onFocus && (
                    <RecentSearches
                        data={recentSearches?.perks}
                        type="perks"
                        collection={searchCollection} />
                )}

                {/* {sponsoredBlock */}
                {/*    && ( */}
                {/*        <div className="mobile:hidden w-screen left-[-24px] mt-6 mb-6 relative"><SponsoredByBlock */}
                {/*            item={sponsoredBlock} /> */}
                {/*        </div> */}
                {/*    )} */}

                <div
                    className="grid lg:grid-cols-2 lg:gap-8 sm:gap-4 md:grid-cols-2 sm:grid-cols-1 gap-4 mobile:mt-9 mt-4">
                    {/** ********************************** PERK OF THE DAY ********************************** */}
                    {perksHomePage?.perkOfTheDay && (
                        <div className="relative">
                            <span className="heading-font font-medium tracking-tight text-black text-18">
                                {t('home.swap-of-the-day')}
                            </span>

                            <PerkOfTheDay item={perksHomePage?.perkOfTheDay} type="perk" />
                        </div>
                    )}
                    {/** ********************************** PERK OF THE DAY ********************************** */}

                    {/** ********************************* PERK OF THE MONTH ********************************* */}
                    {perksHomePage?.freePerkOfTheMonth && (
                        <div>
                            <span className="heading-font font-medium tracking-tight font text-black text-18">
                                {t('lower.swap-of-the-month')}
                            </span>

                            <PerkOfTheMonth item={perksHomePage?.freePerkOfTheMonth} image type="perk" />
                        </div>
                    )}
                    {/** ******************************** PERK OF THE MONTH ********************************* */}
                </div>

                {/** ************************************* PERK SLOT ONE ************************************ */}
                <div className="mt-[60px]">
                    <div className="flex items-end justify-between">
                        <span className="font-medium tracking-tight heading-font font text-black text-18">
                            {perksHomePage?.slotOne?.name}
                        </span>

                        {perksHomePage?.id && (
                            <div onClick={() => navigateViewAll('slotOne')}
                                className="font-semibold not-heading-font tracking-tight underline underline-offset-2 text-rocket text-14">
                                {t('global.view-all')} {perksHomePage?.slotOne?.name}
                            </div>
                        )}
                    </div>

                    <div className="grid laptop:gap-8 md:grid-cols-3 grid-cols-2 gap-[24px] mt-3">
                        {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                        {(perksHomePage?.slotOne?.perks?.length > 0 ? perksHomePage?.slotOne?.perks : dataSlot).slice(0, 6)?.map((item) => (
                            <div key={item?.id}
                                className="over:bg-[#f2f2f2] hover:drop-shadow-[0.3 alpha] hover:ease-in hover:delay-200  hover:duration-200">
                                <PerkCard item={item} type="perk" />
                            </div>
                        ))}
                    </div>
                </div>
                {/** ************************************* PERK SLOT ONE ************************************ */}

                {/** ************************************* PERK SLOT TWO ************************************ */}
                <div className="mt-[60px]">
                    <div className="flex items-end justify-between">
                        <span className="font-medium tracking-tight heading-font font text-black text-18">
                            {perksHomePage?.slotTwo?.name}
                        </span>

                        {perksHomePage?.id && (
                            <div onClick={() => navigateViewAll('slotTwo')}
                                className="font-semibold not-heading-font tracking-tight underline underline-offset-2 text-rocket text-14">
                                {t('global.view-all')} {perksHomePage?.slotTwo?.name}
                            </div>
                        )}
                    </div>

                    <div className="grid laptop:gap-8 md:grid-cols-3 grid-cols-2 gap-[24px] mt-3">
                        {perksHomePage?.slotTwo?.perks?.slice(0, 6).map((item) => (
                            <div key={item.id}
                                className="over:bg-[#f2f2f2] hover:drop-shadow-[0.3 alpha] hover:ease-in hover:delay-200  hover:duration-200">
                                <PerkCard item={item} type="perk" />
                            </div>
                        ))}
                    </div>
                </div>
                {/** ************************************* PERK SLOT TWO ************************************ */}

                {/** *********************************** PERK WE RECOMMEND *********************************** */}
                {perksHomePage?.weRecommend && (
                    <div className="cursor-pointer mt-[60px]">
                        <span className="font-medium tracking-tight heading-font font text-black text-18">
                            {t('lower.we-recommend')}
                        </span>

                        <PerkWeRecommendCard item={perksHomePage?.weRecommend} />
                    </div>
                )}
                {/** *********************************** PERK WE RECOMMEND *********************************** */}

                {/** ************************************ PERK SLOT THREE ************************************ */}
                <div className="mt-[60px]">
                    <div className="flex items-end justify-between">
                        <span className="font-medium tracking-tight font heading-font text-black text-18">
                            {perksHomePage?.slotThree?.name}
                        </span>

                        {perksHomePage?.id && (
                            <div onClick={() => navigateViewAll('slotThree')}
                                className="font-semibold not-heading-font tracking-tight underline underline-offset-2 text-rocket text-14">
                                {t('global.view-all')} {perksHomePage?.slotThree?.name}
                            </div>
                        )}
                    </div>

                    <div className="grid laptop:gap-8 md:grid-cols-3 grid-cols-2 gap-[24px] mt-3">
                        {perksHomePage?.slotThree?.perks?.slice(0, 3).map((item) => (
                            <div key={item.id}
                                className="over:bg-[#f2f2f2] hover:drop-shadow-[0.3 alpha] hover:ease-in hover:delay-200  hover:duration-200">
                                <PerkCard item={item} type="perk" />
                            </div>
                        ))}
                    </div>
                </div>
                {/** ************************************ PERK SLOT THREE ************************************ */}

                {/** ************************************* PERK SLOT FOUR ************************************ */}
                <div className="mt-[60px]">
                    <div className="flex items-end justify-between">
                        <span className="font-medium tracking-tight heading-font font text-black text-18">
                            {perksHomePage?.slotFour?.name}
                        </span>

                        {perksHomePage?.id && (
                            <div onClick={() => navigateViewAll('slotFour')}
                                className="font-semibold not-heading-font tracking-tight underline underline-offset-2 text-rocket text-14">
                                {t('global.view-all')} {perksHomePage?.slotFour?.name}
                            </div>
                        )}
                    </div>

                    <div className="grid laptop:gap-8  md:grid-cols-3 grid-cols-2 gap-[24px] mt-3">
                        {perksHomePage?.slotFour?.perks?.slice(0, 3).map((item) => (
                            <div key={item.id}
                                className="over:bg-[#f2f2f2] hover:drop-shadow-[0.3 alpha] hover:ease-in hover:delay-200  hover:duration-200">
                                <PerkCard item={item} type="perk" />
                            </div>
                        ))}
                    </div>
                </div>
                {/** ************************************* PERK SLOT FOUR ************************************ */}
            </div>
        </Layout>
    )
}
