import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { getUser } from '../../state/user/selectors'
import { fetchBadgeTasks, fetchUser } from '../../state/user/actions'
import BadgeTaskItem from '../../components/BadgeTaskItem'
import BadgeItem from '../../components/BadgeItem'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import { getHomePageLists } from '../../state/home/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'

export default function Badges() {
    const dispatch = useDispatch()
    const { badgeTasksList } = useSelector(getUser)
    const { user } = useSelector(getUser)
    const { navbarHeight } = useContext(Context)
    const [t] = useTranslation('global')

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        dispatch(fetchBadgeTasks)
        dispatch(fetchUser)
        // eslint-disable-next-line
    }, [])

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Achievements')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Badges' })
    }, [])

    return (
        <Layout contentContainerClassName="flex-col h-screen">
            {sponsoredBlock && <div className="mobile:hidden w-screen left-[-24px]"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div className="w-full flex flex-col items-center">
                <div style={{ top: navbarHeight }} className="w-11/12 medium:w-[50%] laptop:mx-[223px] tablet:mx-[103px] mobile:mx-10 mx-4 mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]">
                    <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 tablet:mr-[92px] medium:mr-[88px] mobile:mr-10 laptop:w-[760px]">
                        <h1 className="heading-uppercase heading-font hidden medium:block mobile:text-36 text-26 font tracking-tight font-medium text-black">
                            {t('me.achievements')}
                        </h1>

                        <div className="medium:mt-8">
                            {badgeTasksList?.map((item) => (
                                <BadgeTaskItem item={item} />
                            ))}

                            {user?.earnedBadges?.map((item) => (
                                <BadgeItem item={item} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
