import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import AccountSidebar from '../../components/account/AccountSidebar'
import { getPrizeDraw } from '../../state/prize/selectors'
import { fetchPrizeDraw } from '../../state/prize/actions'

export default function PrizeDraw() {
    const dispatch = useDispatch()
    const { prizeDraw } = useSelector(getPrizeDraw)
    const [t] = useTranslation('global')

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchPrizeDraw)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Prize draw' })
    }, [])

    return (
        <AccountSidebar prizeDraw>
            <div className="mobile:mt-[52px] mt-[32px] flex-1 tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 tablet:mr-[92px] medium:mr-[88px] mobile:mr-10 laptop:w-[760px]">
                <h1 className="heading-uppercase heading-font mobile:text-32 text-26 font tracking-tight font-medium text-black mt-1.5">
                    {t('account.Prize draw')}
                </h1>

                <img src={prizeDraw?.heroImage} alt="" className="tablet:w-[764px] w-full mobile:h-[407px] h-auto object-cover mt-[29px] rounded-lg" />

                {/* eslint-disable-next-line react/no-danger */}
                <h1 className="rich-text mt-[32px]" dangerouslySetInnerHTML={{ __html: prizeDraw?.prizeDescription }} />
            </div>
        </AccountSidebar>
    )
}
