import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import Layout from '../components/layout/Layout'
import { travelSelector } from '../state/travel/selectors'
import { ChevronUp, LocationIcon, TravelTreeBigEllipse } from '../components/common/Svg'
import { IPlanJourneyLeg, IPlanJourneyTransport } from '../types/data'
import { displayDistanceWithUnit, convertSecondsToMinutesAndHours, readableC02, replaceAll } from '../utils/helpers'
import { COLOR_CONST } from '../constants'
import { getWhiteLabelingList } from '../state/user/selectors'
import ShowWithAnimation from '../components/ShowWithAnimation'
import Button from '../components/common/Button'
import PlanJourneyLegItem from '../components/PlanJourneyLegItem'
import { Context } from '../components/common/BaseContext'

function PlanJourneyItem({ item, name, googleMapTravelMode }: {item: IPlanJourneyTransport, name: string, googleMapTravelMode: string}) {
    const { planJourneyData } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const { distanceUnit } = useContext(Context)

    const [isDetailsShown, setIsDetailsShown] = useState(false)

    const onOpenMap = () => {
        const url = `https://www.google.com/maps/dir/?api=1&map_action=map&origin=${planJourneyData.startPlaceLat},${planJourneyData.startPlaceLon},-2.320096&origin_place_id=${planJourneyData.startPlaceId}&destination=${planJourneyData.endPlaceLat},${planJourneyData.endPlaceLon}&destination_place_id=${planJourneyData.endPlaceId}&travelmode=${googleMapTravelMode}&dir_action=navigate`
        window.open(url, '_blank')
    }

    return (
        <div className="mt-5 border-b-spinach border-b pb-4">
            <div onClick={() => setIsDetailsShown(!isDetailsShown)} className="justify-between flex cursor-pointer">
                <div className="font-medium not-heading-font">
                    {name}
                </div>

                <div className="text-14 flex justify-between">
                    <div className="mr-2">
                        {convertSecondsToMinutesAndHours(item.durationSeconds)}, {readableC02(item.ceo2Kg)}
                    </div>

                    <ChevronUp style={{ transform: isDetailsShown ? 'rotate(180deg)' : undefined, transition: 'transform 0.2s ease' }} />
                </div>
            </div>

            <ShowWithAnimation isShown={isDetailsShown}>
                <div className="flex justify-between items-end">
                    <div className="flex items-end pt-7">
                        <div>
                            <TravelTreeBigEllipse />
                        </div>

                        <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                            {planJourneyData.startPlaceName?.slice(0, planJourneyData.startPlaceName?.indexOf(','))}
                        </div>
                    </div>

                    <div onClick={onOpenMap} className="cursor-pointer flex rounded px-2 py-1.5 items-center bg-pistachio border-spinach border">
                        <LocationIcon />
                        {t('plan.map')}
                    </div>
                </div>

                <div className="border-l-[10px] border-spinach mt-[-3px] ml-3 pl-5 py-6">
                    <div className="flex items-center h-full">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div className="mr-3.5" dangerouslySetInnerHTML={{ __html: replaceAll(item?.icon || '', COLOR_CONST, whiteLabeling.cavolo) }} />

                        <div>
                            <h1 className="text-14 tracking-tight text-caovolo xs-mobile:w-[337px]">
                                {name}, {convertSecondsToMinutesAndHours(item.durationSeconds)}, {displayDistanceWithUnit(item.distanceMeters / 1000, distanceUnit, t)}
                            </h1>

                            <h1 className="text-14 tracking-tight text-caovolo xs-mobile:w-[337px]">
                                {readableC02(item.ceo2Kg)}
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="flex mt-[-2px]">
                    <div>
                        <TravelTreeBigEllipse />
                    </div>

                    <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                        {planJourneyData.endPlaceName?.slice(0, planJourneyData.endPlaceName?.indexOf(','))}
                    </div>
                </div>
            </ShowWithAnimation>
        </div>
    )
}

export function PlanJourneyTransitItem({ item, setLegsEndPlaceNames, name, googleMapTravelMode, disableDropdown = false, planJourneyData }: {item: IPlanJourneyTransport, name: string, googleMapTravelMode: string, disableDropdown?: boolean, planJourneyData: any, setLegsEndPlaceNames?: (value: object) => void}) {
    const [isDetailsShown, setIsDetailsShown] = useState(disableDropdown)
    const [t] = useTranslation('global')

    const onOpenMap = () => {
        const url = `https://www.google.com/maps/dir/?api=1&map_action=map&origin=${planJourneyData.startPlaceLat},${planJourneyData.startPlaceLon},-2.320096&origin_place_id=${planJourneyData.startPlaceId}&destination=${planJourneyData.endPlaceLat},${planJourneyData.endPlaceLon}&destination_place_id=${planJourneyData.endPlaceId}&travelmode=${googleMapTravelMode}&dir_action=navigate`
        window.open(url, '_blank')
    }

    return (
        <div className={cn('mt-5 pb-4', { 'border-b border-b-spinach': !disableDropdown })}>
            {!disableDropdown && (
                <div onClick={() => setIsDetailsShown(!isDetailsShown)} className="justify-between flex cursor-pointer">
                    <div className="font-medium not-heading-font">
                        {name}
                    </div>

                    <div className="text-14 flex justify-between">
                        <div className="mr-2">
                            {convertSecondsToMinutesAndHours(item.durationSeconds)}, {readableC02(item.ceo2Kg)}
                        </div>

                        <ChevronUp style={{ transform: isDetailsShown ? 'rotate(180deg)' : undefined, transition: 'transform 0.2s ease' }} />
                    </div>
                </div>
            )}

            {disableDropdown ? (
                <div>
                    <div className="flex justify-between items-end">
                        <div className={`flex items-end ${!disableDropdown && 'pt-7'}`}>
                            <div>
                                <TravelTreeBigEllipse />
                            </div>

                            <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                                {planJourneyData.startPlaceName?.slice(0, planJourneyData.startPlaceName?.indexOf(','))}
                            </div>
                        </div>

                        <div onClick={onOpenMap}
                            className="cursor-pointer flex rounded px-2 py-1.5 items-center bg-pistachio border-spinach border">
                            <LocationIcon />
                            {t('plan.map')}
                        </div>
                    </div>

                    {item?.legs?.map((leg: IPlanJourneyLeg, index: number, legs: any) => {
                        // const ce2oCalc = calculating.filter((i) => i.startPlaceId === item.startPlaceId && i.endPlaceId === item.endPlaceId && i.transport === item.transportId)[0]
                        const isLast = index + 1 === legs?.length

                        return (
                            <div key={leg.polyline.encodedPolyline}>
                                <PlanJourneyLegItem
                                    setLegsEndPlaceNames={setLegsEndPlaceNames}
                                    legs={legs}
                                    item={leg}
                                    isLast={isLast}
                                    index={index}
                                    disableDropdown={disableDropdown} />
                            </div>
                        )
                    })}

                    <div className="flex mt-[-2px]">
                        <div>
                            <TravelTreeBigEllipse />
                        </div>

                        <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                            {planJourneyData.endPlaceName?.slice(0, planJourneyData.endPlaceName?.indexOf(','))}
                        </div>
                    </div>
                </div>
            ) : (
                <ShowWithAnimation isShown={isDetailsShown}>
                    <div className="flex justify-between items-end">
                        <div className={`flex items-end ${!disableDropdown && 'pt-7'}`}>
                            <div>
                                <TravelTreeBigEllipse />
                            </div>

                            <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                                {planJourneyData.startPlaceName?.slice(0, planJourneyData.startPlaceName?.indexOf(','))}
                            </div>
                        </div>

                        <div onClick={onOpenMap}
                            className="cursor-pointer flex rounded px-2 py-1.5 items-center bg-pistachio border-spinach border">
                            <LocationIcon />
                            {t('plan.map')}
                        </div>
                    </div>

                    {item?.legs?.map((leg: IPlanJourneyLeg, index: number, legs: any) => {
                    // const ce2oCalc = calculating.filter((i) => i.startPlaceId === item.startPlaceId && i.endPlaceId === item.endPlaceId && i.transport === item.transportId)[0]
                        const isLast = index + 1 === legs?.length

                        return (
                            <div key={leg.polyline.encodedPolyline}>
                                <PlanJourneyLegItem
                                    setLegsEndPlaceNames={setLegsEndPlaceNames}
                                    legs={legs}
                                    item={leg}
                                    isLast={isLast}
                                    index={index}
                                    disableDropdown={disableDropdown} />
                            </div>
                        )
                    })}

                    <div className="flex mt-[-2px]">
                        <div>
                            <TravelTreeBigEllipse />
                        </div>

                        <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                            {planJourneyData.endPlaceName?.slice(0, planJourneyData.endPlaceName?.indexOf(','))}
                        </div>
                    </div>
                </ShowWithAnimation>
            )}
        </div>
    )
}

export default function PlanJourneyResult() {
    const [t] = useTranslation('global')
    const { planJourneyData } = useSelector(travelSelector)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    return (
        <Layout showFooter={false}>
            <div
                className="w-full min-h-screen pb-[300px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                <div className="max-w-[600px] w-full">
                    <h1 className="heading-uppercase heading-font text-cavolo text-22 tracking-tight font-semibold mt-2">
                        {t('plan.sustainable-journey-planner')}
                    </h1>

                    {planJourneyData.transit
                        && (
                            <PlanJourneyTransitItem item={planJourneyData.transit} name={t('plan.public-transport')}
                                googleMapTravelMode="transit" planJourneyData={planJourneyData} />
                        )}
                    {planJourneyData.bicycle
                        && <PlanJourneyItem item={planJourneyData.bicycle} name={t('plan.cycle')} googleMapTravelMode="bicycling" />}
                    {planJourneyData.walk
                        && <PlanJourneyItem item={planJourneyData.walk} name={t('plan.walk')} googleMapTravelMode="walking" />}
                    {planJourneyData.drive
                        && <PlanJourneyItem item={planJourneyData.drive} name={t('plan.drive')} googleMapTravelMode="driving" />}

                    <Button text={t('plan.plan-another-journey')} onClick={() => navigate('/plan/plan-journey')}
                        style={{ marginTop: 30, marginBottom: 30 }} />
                </div>
            </div>
        </Layout>
    )
}
