import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import RecipeDataContainer from './RecipeDataContainer'
import { getRecipe } from '../../state/recipe/selectors'
import SecondButton from '../common/SecondButton'
import ModalPopupWindow from '../ModalPopupWindow'
import { ArrowBottomIcon } from '../common/Svg'
import { readableC02 } from '../../utils/helpers'
import { postRecipePledgeLog } from '../../state/recipe/actions'
import { getUser } from '../../state/user/selectors'

export default function RecipeDetailBody() {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const { recipeDetail: recipe } = useSelector(getRecipe)
    const [showModalPoint, setShowModalPoint] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState({ message: '' })
    const [t] = useTranslation('global')

    if (!recipe) return <div />

    const onSubmit = async () => {
        setLoading(true)
        try {
            await dispatch(
                postRecipePledgeLog(
                    recipe?.id,
                    () => {
                        setShowModalPoint(true)
                    },
                    setError,
                ),
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="w-full laptop:flex flex-col justify-center items-center">
            <div className="laptop:max-w-[1600px] w-full">
                <RecipeDataContainer leftElement={(
                    <>
                        {user?.company?.gamification && (
                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <p className="font-normal text-grey01 text-14">
                                        {t('lower.did-you-make-this')}
                                    </p>

                                    <div className="flex items-center">
                                        <ArrowBottomIcon width={15} height={15} />

                                        <p className="font-semibold not-heading-font text-grey01 ml-1.5 text-12">
                                            {readableC02(recipe?.ceo2Kg || 0)}
                                        </p>
                                    </div>
                                </div>

                                <SecondButton
                                    loading={loading}
                                    onClick={onSubmit}
                                    text={t('lower.log-a-single-serving')}
                                    className="mt-2.5 w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                                />

                                <p className="mt-2 text-14 text-error font-semibold">
                                    {error?.message}
                                </p>
                            </div>
                        )}

                        <h1 className="heading-uppercase heading-font font tracking-tight font-medium text-22 text-black mb-2">
                            {t('lower.ingredients')}
                        </h1>

                        {/* eslint-disable-next-line react/no-danger */}
                        <h1 className="rich-text" dangerouslySetInnerHTML={{ __html: recipe?.ingredients }} />
                    </>

                )} rightElement={(
                    <>
                        <h1 className="heading-font font tracking-tight font-medium text-22 text-black mb-2">
                            {t('lower.method')}
                        </h1>

                        {/* eslint-disable-next-line react/no-danger */}
                        <h1 className="rich-text" dangerouslySetInnerHTML={{ __html: recipe?.method }} />
                    </>
                )} />

                <ModalPopupWindow
                    isShown={showModalPoint}
                    onClose={() => setShowModalPoint(false)}
                    point={5}
                    description={(
                        <h1 className="text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                            {t('lower.you’ve-saved')} <span className="text-center mobile:text-22 text-18 text-black tracking-tight font-semibold not-heading-font font mobile:leading-[28.6px] leading-[23.6px]">{readableC02(recipe?.ceo2Kg || 0)}</span> {t('lower.and-received-5-points-from-logging-a-serving')}
                        </h1>
                    )} />
            </div>
        </div>
    )
}
