import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import Layout from '../../components/layout/Layout'
import { getHomePageLists } from '../../state/home/selectors'
import { Context } from '../../components/common/BaseContext'
import { fetchTravelArticles } from '../../state/travel/actions'
import { travelArticlesList } from '../../state/travel/selectors'
import ArticleCard from '../../components/article/ArticleCard'

export default function TravelArticles() {
    const dispatch = useDispatch()
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Pledges')
    const [t] = useTranslation('global')
    const { screenWidth } = useContext(Context)
    const { travelArticles } = useSelector(travelArticlesList)

    useEffect(() => {
        dispatch(fetchTravelArticles())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    return (
        <Layout contentContainerClassName="flex-col">
            {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div
                className={`overflow-x-hidden ${screenWidth < 1500 && 'laptop:mx-[223px] tablet:mx-[103px]'} mobile:mx-10 mx-4 mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]`}>
                <h1 className="heading-uppercase heading-font medium:block hidden text-black laptop:text-36 medium:text-36 mobile:text-32 text-26 font tracking-tight font-medium">
                    {t('lower.travel')}
                </h1>

                <h1 className="font-normal text-grey01 text-16 mt-2">
                    {t('lower.travel-description')}
                </h1>

                <div className="grid laptop:gap-8 md:grid-cols-3 grid-cols-2 gap-6 mt-10 mb-4">
                    {travelArticles?.articles?.map((item) => (
                        <ArticleCard key={item.id} item={item} type="travel" />
                    ))}
                </div>
            </div>
        </Layout>

    )
}
