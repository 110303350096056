import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { getRecipe } from '../../state/recipe/selectors'
import RecipeCard from '../../components/recipe/RecipeCard'
import { fetchBookmarkedRecipe, fetchRecipeCategoryList, fetchRecipeTagsList } from '../../state/recipe/actions'
import RecipeCategories from '../../components/common/RecipeCategories'
import RecipeTags from '../../components/common/RecipeTags'
import { FilterIcon } from '../../components/common/Svg'
import SearchInput from '../../components/common/SearchInput'
import Pagination from '../../components/common/Pagination'
import { getPerkLists } from '../../state/perks/selectors'
import RecentSearches from '../../components/RecentSearches'

export default function RecipeBookmark() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { myBookmarks, loading, recipeCategories, searchCollection } = useSelector(getRecipe)
    const { recentSearches } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Recipe bookmark' })
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchBookmarkedRecipe({ page: 1 }))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        if (myBookmarks?.results?.length === 0) onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (recipeCategories.length === 0) dispatch(fetchRecipeCategoryList)
        // eslint-disable-next-line
    }, [])

    const data = [
        { id: 1, title: t('lower.all-categories'), content: <RecipeCategories />, loadContent: () => dispatch(fetchRecipeCategoryList) },
        { id: 2, title: t('lower.tags'), content: <RecipeTags />, loadContent: () => dispatch(fetchRecipeTagsList) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('lower.recipes'),
    }

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="medium:mt-[52px] mt-[32px] min-h-screen mx-12 tablet:mb-[120px] mb-[80px] mobile:mx-10 mx-4">
                <span onClick={() => navigate('/lower/recipes')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('account.Recipes')}
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="heading-uppercase heading-font text-black xs-mobile:block hidden font tracking-tight font-medium tablet:text-36 mobile:text-32 text-26">
                        {t('lower.my-recipes')}
                    </h1>

                    <SearchInput action="recipes" placeholder={t('lower.what-would-you-like-to-make')} onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches data={recentSearches?.recipes} type="recipes" collection={searchCollection} />
                )}

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/recipes')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('account.Recipes')}
                    </span>

                    <h1 className="text-black font tracking-tight font-medium mt-2 text-26">
                        {t('lower.my-recipes')}
                    </h1>
                </div>

                <div className="grid md:grid-cols-3 sm:grid-cols-1 mobile:gap-[24px] tablet:gap-[24px] laptop:gap-[32px] mt-8">
                    { myBookmarks.results?.length !== 0 && myBookmarks.results?.map((item) => <RecipeCard key={item.id} item={item} type="recipe" />)}
                </div>

                <Pagination
                    loading={loading}
                    loadMore={(page) => dispatch(fetchBookmarkedRecipe({ page }))}
                    containerClassName="mt-[60px]"
                    totalCount={myBookmarks?.count}
                    currentCount={myBookmarks?.results?.length} />
            </div>
        </Layout>
    )
}
