import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '../common/BaseContext'
import { DismissIcon } from '../common/Svg'
import SecondButton from '../common/SecondButton'

interface IProps {
    isShown?: boolean
    onClose: () => void
    onContinue: () => void
    description: string
}

export default function QuizQuitOverlayModal({ isShown = false, onClose, onContinue, description }: IProps) {
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    return (
        <div>
            {isShown && (
                <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                    <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                    <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                        <div className="bg-cloud relative xs-mobile:w-[500px] w-11/12 mobile:px-4 px-3 rounded-[16px]" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                            <div onClick={onContinue} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center mt-[16px]">
                                <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                            </div>

                            <div className="flex flex-col justify-center items-center pb-[32px]">
                                <div className="xs-mobile:w-[296px] w-[242px] flex flex-col items-center justify-center">
                                    <h1 className="heading-uppercase heading-font mobile:text-22 text-18 text-darken font-medium tracking-[-0.02rem] font">
                                        {t('log.are-you-sure?')}
                                    </h1>

                                    <h1 className="mobile:w-[370px] text-center text-16 mt-[4px] text-grey01 tracking-tight font-normal leading-[24px]">
                                        {description}
                                    </h1>
                                </div>

                                <div className="flex justify-between w-[249px] mt-[32px]">
                                    <SecondButton onClick={onClose} text={t('log.quit')} className="w-[89px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />

                                    <button
                                        onClick={onContinue}
                                        className="border-2 border-solid border-rocket bg-mint hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D] w-[136px] h-[48px] font-semibold rounded-md text-14 text-marrow not-heading-font">
                                        {t('log.keep-going')}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
