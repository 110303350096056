import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import lodash from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { travelSelector } from '../../state/travel/selectors'
import { ArrowTravel } from '../../components/common/Svg'
import { readableC02, readableCO2 } from '../../utils/helpers'
import { ITravelData } from '../../types/data'

export default function TrackTravelSavedRoutes() {
    const navigate = useNavigate()
    const { savedRoutes } = useSelector(travelSelector)
    const [t] = useTranslation('global')

    function getTotalDistance(commute: ITravelData) {
        let distance = commute.distanceInMil ? `${commute.distanceInMil} mil${commute.distanceInKm ? ', ' : ''}` : ''
        distance += commute.distanceInKm ? `${commute.distanceInKm} km` : ''
        if (!commute.distanceInKm && !commute.distanceInMil) return '0 km'

        const numberValue = parseFloat((distance.match(/\d+\.\d+|\d+/g) ?? [])[0] || '0')
        const co2Value = readableCO2(numberValue)
        return `${co2Value} km`
    }

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel saved routes' })
    }, [])

    return (
        <div className="w-full">
            <div className="justify-end medium:mt-[52px] mt-[32px] pl-5 mobile:pl-[45px] pr-4 mobile:mx-[42px]">
                <div onClick={() => navigate(-1)} className="flex cursor-pointer">
                    <ArrowTravel width={22} height={22} />
                </div>

                <div className="flex flex-col items-center mobile:mt-[30px]">
                    <div className="max-w-[764px] w-full">
                        <div className="flex items-center justify-between mb-[3px] mt-[30px]">
                            <h1 className="heading-uppercase heading-font font-bold text-22 text-black">
                                {t('global.saved-routes')}
                            </h1>
                        </div>

                        {savedRoutes?.map((item) => (
                            <div key={item?.id} className="mt-[16px]">
                                <div className="flex items-center justify-between">
                                    <p className="text-14 font-bold text-black">
                                        {item?.name}
                                    </p>

                                    <div onClick={() => item?.commute.startPlaceId && navigate('/log/track-travel/log-route', { state: { commute: item.commute } })} className={`flex items-center ${item?.commute.startPlaceId && 'cursor-pointer'}`}>
                                        <p className={`text-14 font-bold ${item?.commute.startPlaceId ? 'text-black' : 'text-grey02'} mr-[4px]`}>
                                            + {t('global.log-route')}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mt-[4px]">
                                    <p className="font-normal text-14 text-grey01">
                                        {getTotalDistance(item.commute)}
                                    </p>

                                    <p className="font-normal text-14 text-grey01">
                                        {t('log.by')}&nbsp;
                                        {lodash
                                            .uniq(item?.commute?.legs?.map((i) => i?.transport?.name))
                                            .join(', ')}
                                    </p>
                                    <p className="font-normal not-heading-font text-14 text-grey01">
                                        +{readableC02(item?.commute?.totalCeo2Kg || 0)}
                                    </p>
                                </div>

                                <div className="w-full h-[1px] bg-grey01 mt-[6px]" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
