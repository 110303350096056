import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import isEmpty from 'lodash/isEmpty'
import { fetchPrizeDraw } from '../../state/prize/actions'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import LeaderboardUserItems from '../../components/LeaderboardUserItems'
import { fetchClearLeaderboard, fetchLeaderboard } from '../../state/leaderboard/actions'
import { getLeaderboard } from '../../state/leaderboard/selectors'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import { getHomePageLists } from '../../state/home/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { LeaderboardBadge, UserIcon } from '../../components/common/Svg'
import { formatDate } from '../../utils/date'
import { getPrizeDraw } from '../../state/prize/selectors'
import GoldZoneBlock from '../../components/GoldZoneBlock'
import Skeleton from '../../components/common/Skeleton'

export default function Leaderboard() {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const { leaderboard, loading } = useSelector(getLeaderboard)
    const [showSwitch, setShowSwitch] = useState<number>(0)
    const { navbarHeight } = useContext(Context)
    const [t] = useTranslation('global')
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const languageData = localStorage.getItem('language') || 'en'

    const { prizeDraw } = useSelector(getPrizeDraw)

    const onRestartLoad = useCallback(() => {
        dispatch(fetchPrizeDraw)
        dispatch(fetchLeaderboard({ page: 1 }))
        dispatch(fetchClearLeaderboard())
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Leaderboard' })
    }, [])

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Leaderboard')

    const switchSelectorFunc = (index: number, id: number | string) => {
        setShowSwitch(index)
        dispatch(fetchClearLeaderboard())
        dispatch(fetchLeaderboard({ page: 1, leaderboard: id }))
    }

    const switchData = leaderboard?.leaderboards.map((item) => ({
        id: item?.id,
        name: item?.entry?.name || 'Individual',
        icon: item?.isUserLeaderboard ? UserIcon : LeaderboardBadge,
        image: item?.entry?.image,
        isUserLeaderboard: item?.isUserLeaderboard,
    }))

    return (
        <Layout contentContainerClassName="flex-col">
            {sponsoredBlock && <div className="mobile:hidden w-screen left-[-24px]"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div className="w-full flex flex-col items-center">
                <div style={{ top: navbarHeight }} className="w-11/12 medium:w-[50%] laptop:mx-[223px] tablet:mx-[103px] mobile:mx-10 mx-4 mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]">
                    <h1 className="heading-uppercase heading-font hidden medium:block tablet:text-36 mobile:text-32 text-26 font tracking-tight font-medium text-black mt-1">
                        {switchData?.length > 1 ? t('global.leaderboards') : t('global.leaderboard')}
                    </h1>

                    {isEmpty(switchData) ? (
                        <h1 className="h-screen text-18">
                            {t('me.no-leaderboard-is-configured')}
                        </h1>
                    ) : (
                        <div>
                            {switchData?.length > 1 && (
                                <div className="mb-[15px] mt-1 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                                    {/* <div className="grid grid-cols-2 gap-8 mt-1 mb-[15px]"> */}
                                    {switchData?.map((item, index) => {
                                        const IconComponent = item.icon
                                        const isActive = showSwitch === index

                                        return (
                                            <div key={item?.id} onClick={() => switchSelectorFunc(index, item?.id)}
                                                className={`${isActive ? 'bg-rocket border-rocket border' : 'bg-white border-grey01 border'} flex items-center justify-center w-full h-[32px] rounded-md cursor-pointer`}>
                                                <h1 className={`text-14 ${isActive ? 'text-pistachio' : 'text-grey01'} font-semibold mr-2`}>
                                                    {item?.name}
                                                </h1>

                                                {item?.image ? (
                                                    <img className="object-cover w-[17px] h-[17px]" src={item.image} alt="" />
                                                ) : (
                                                    <IconComponent color={isActive ? whiteLabeling?.pistachio : whiteLabeling?.grey01} />
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                            <h1 className="font-medium text-18 medium:mt-[39px] font text-black">
                                {t('me.your-standing')}
                            </h1>
                        </div>
                    )}

                    {loading ? (
                        <div className="grid xs-mobile:grid-cols-3 grid-cols-2 gap-3 mt-2.5">
                            <Skeleton className="mobile:h-[82px] xs-mobile:h-[55px] rounded-lg" />
                            <Skeleton className="mobile:h-[82px] xs-mobile:h-[55px] rounded-lg" />
                            <Skeleton className="mobile:h-[82px] xs-mobile:h-[55px] rounded-lg" />
                        </div>
                    ) : (
                        <div className="grid xs-mobile:grid-cols-3 grid-cols-2 gap-3 mt-[10px]">
                            <div className="flex flex-col mobile:h-[82px] xs-mobile:h-[55px] bg-grey04 rounded-lg mobile:p-[16px] py-[8px] px-[12px]">
                                <span className="font font-medium not-heading-font w-full tracking-[-0.02rem] text-cavolo text-16 mobile:text-[22px] mobile:leading-[29.04px] leading-[21.12px]">
                                    {leaderboard.myRank}/{leaderboard?.count}
                                </span>

                                <span className="tracking-[-0.01rem] w-full text-cavolo text-12 mobile:text-14 mobile:leading:[21px] leading:[18px]">
                                    {t('me.current-place')}
                                </span>
                            </div>

                            <div
                                className="flex flex-col mobile:h-[82px] xs-mobile:h-[55px] bg-grey04 rounded-lg mobile:p-[16px] py-[8px] px-[12px]">
                                <span
                                    className="font font-medium not-heading-font w-full tracking-[-0.02rem] leading-[126%] text-cavolo text-16 mobile:text-[22px] mobile:leading-[29.04px]">
                                    {typeof leaderboard.thisMonthPoints === 'number' ? leaderboard.thisMonthPoints : user?.thisMonthPoints || 0} {t('global.pts')}
                                </span>

                                <span
                                    className="tracking-[-0.01rem] w-full text-cavolo text-12 mobile:text-14 mobile:leading:[21px] leading:[18px]">
                                    {`${t('global.since')} ${formatDate(prizeDraw.startsAt, languageData)}`}
                                </span>
                            </div>

                            <GoldZoneBlock />
                        </div>
                    )}

                    <h1 className="font-medium text-18 mt-[39px] font text-black">
                        {t('me.all-standings')}
                    </h1>

                    <LeaderboardUserItems />
                </div>
            </div>
        </Layout>
    )
}
