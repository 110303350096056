import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import isEmpty from 'lodash/isEmpty'
import { ArrowTravel, DefaultHeartIcon, DismissIcon } from '../../components/common/Svg'
import Button from '../../components/common/Button'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { getSavedPlacesList, getUser } from '../../state/user/selectors'
import { ILegs } from '../../types/data'
import { changeCommuteDestination,
    changeLegStartEnd,
    clearLegTransport,
    editTravelCommute } from '../../state/travel/actions'
import GoogleAutoComplete from '../../components/GoogleAutoComplete'
import { Colors } from '../../themes'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'

import { changeLegFromLocalstorage,
    getLocationByPlaceId,
    legDefaultsHelpText } from '../../utils/travel'
import { TRAVEL_TEXT_SPLITTER } from '../../constants'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import { legTransportList, travelSelector } from '../../state/travel/selectors'
import LogTravelCarDetails from './LogTravelCarDetails'
import TrackTravelModality from './TrackTravelModality'
import { travelDate } from '../../utils/date'

export default function LegTravelStartEnd() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = useLocation()
    const today = new Date()
    const { navbarHeight, screenWidth, isEmbedded } = useContext(Context)
    const { user } = useSelector(getUser)
    const { legTransports, commuteStartEnd, travelAccommodation, legStartEnd } = useSelector(legTransportList)
    const { transportModes } = useSelector(travelSelector)
    const [loading, setLoading] = useState<boolean>(false)
    const [t] = useTranslation('global')

    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const [startPlace, setStartPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const [endPlace, setEndPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })

    const [startLeg, setStartLeg] = useState('')
    const [endLeg, setEndLeg] = useState('')
    const [showPage, setShowPage] = useState(false)
    // const [autoSubmit, setAutoSubmit] = useState<boolean>(false)
    // const [moveToTheNextScreen, setMoveToTheNextScreen] = useState<boolean>(false)

    const sizeTypeDatas = location?.state?.sizeTypeData
    const fuelTypeDatas = location?.state?.fuelTypeData
    const sizeCar = location?.state?.sizeCar
    const fuel = location?.state?.fuel
    const byCar = location?.state?.byCar
    const allModeBack = location?.state?.allMode
    const choosedCar = location?.state?.choosedCar
    const impact = location?.state?.impact
    const countOfPeople = location?.state?.countOfPeople
    const goingBackCommute = location?.state?.goingBackCommute
    const edit = location?.state?.edit
    const fromImpact = location?.state?.fromImpact
    const carType = choosedCar?.name?.includes('Car') || choosedCar?.name?.includes('Taxi')
    const places = JSON.parse(localStorage.getItem('places') || '{}')
    const { savedPlaces } = useSelector(getSavedPlacesList)

    const endRef = useRef<any>()

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')
    const returnData = JSON.parse(localStorage.getItem('returnTravelData') || '{}')

    const lowerTransportName = choosedCar?.name.toLowerCase()

    const transportMode = transportModes.filter((i) => lowerTransportName === i.name.toLowerCase() || i?.synonyms?.split(',').includes(lowerTransportName))[0]
    const minDate = outboundData.date.reduce((a: string, b: string) => (new Date(b) < new Date(a) ? b : a))

    const transportVerbData = {
        transportMode: transportMode?.name,
        transportCategory: transportMode?.category === 1 ? 'personal' : 'shared',
        verbFrom: transportMode?.verbFrom,
        verbTo: transportMode?.verbTo,
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    useEffect(() => {
        const placeObject: { [key: string]: string } = {}

        transportModes.forEach((item) => {
            if (item?.stopName && item.places.length > 0) {
                item.places.forEach((place) => {
                    placeObject[place.placeName] = item.stopName!
                })
            }
        })

        localStorage.setItem('places', JSON.stringify(placeObject))
    }, [transportModes])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Leg travel start end' })
    }, [])

    const storedDataEditString = localStorage.getItem('travelDataEdit')
    // @ts-ignore
    const storedDataEdit = JSON.parse(storedDataEditString)

    const legStart = startLeg?.split(',')[0] || '?'
    const legEnd = endLeg?.split(',')[0] || '?'

    function findPlaceType(place: any, placeNames: any) {
        return placeNames[place] || ''
    }

    const legEndType = findPlaceType(legEnd, places)
    const legStartType = findPlaceType(legStart, places)

    const travelDataString = localStorage.getItem('travelData')
    // @ts-ignore
    const initTravelData = JSON.parse(travelDataString)

    const game = initTravelData.gameData

    useEffect(() => {
        const travelData = JSON.parse(localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData') || '{}')

        if (travelData.legStartEndEmpty) {
            setShowPage(true)
            return
        }

        if (travelData.legStartEnd) {
            setStartPlace({
                lat: travelData.legStartEnd.startPlaceLat,
                lon: travelData.legStartEnd.startPlaceLon,
                placeId: travelData.legStartEnd.startPlaceId,
                name: travelData.legStartEnd.startPlaceName,
            })

            setEndPlace({
                lat: travelData.legStartEnd.endPlaceLat,
                lon: travelData.legStartEnd.endPlaceLon,
                placeId: travelData.legStartEnd.endPlaceId,
                name: travelData.legStartEnd.endPlaceName,
            })

            setStartLeg(travelData.legStartEnd.startPlaceName)
            setEndLeg(travelData.legStartEnd.endPlaceName)
            setShowPage(true)
            return
        }

        if (edit && storedDataEdit) {
            setStartPlace({
                lat: storedDataEdit.startPlaceLat,
                lon: storedDataEdit.startPlaceLon,
                placeId: storedDataEdit.startPlaceId,
                name: storedDataEdit.startPlaceName,
            })
            setEndPlace({
                lat: storedDataEdit.endPlaceLat,
                lon: storedDataEdit.endPlaceLon,
                placeId: storedDataEdit.endPlaceId,
                name: storedDataEdit.endPlaceName,
            })

            const defaults = storedDataEdit?.defaults

            if (defaults?.legStart) {
                const splitData = defaults.legStart.split(TRAVEL_TEXT_SPLITTER)
                setStartLeg(splitData[0])
            }

            if (defaults?.legEnd) {
                const splitData = defaults.legEnd.split(TRAVEL_TEXT_SPLITTER)
                setEndLeg(splitData[0])
            }

            setShowPage(true)
            return
        }

        if (legTransports?.defaults?.defaults?.displayStart) {
            const displayStart = legTransports?.defaults?.defaults?.displayStart
            const splitData = displayStart.split(TRAVEL_TEXT_SPLITTER)

            setStartPlace({
                lat: splitData[3],
                lon: splitData[2],
                placeId: splitData[1],
                name: splitData[0],
            })
        }

        if (legTransports?.defaults?.defaults?.displayEnd) {
            // const splitData = legTransports.defaults?.defaults?.displayEnd.split(TRAVEL_TEXT_SPLITTER)

            setShowPage(true)
            // setEndPlace({
            //     lat: splitData[3],
            //     lon: splitData[2],
            //     placeId: splitData[1],
            //     name: splitData[0],
            // })
        }

        const defaults = storedDataEdit?.defaults || legTransports?.defaults

        if (defaults?.legStart) {
            const splitData = defaults.legStart.split(TRAVEL_TEXT_SPLITTER)
            setStartLeg(splitData[0])
            console.log('start leg', splitData[0])
        }

        if (defaults?.legEnd) {
            const splitData = defaults.legEnd.split(TRAVEL_TEXT_SPLITTER)
            setEndLeg(splitData[0])
        }

        if (defaults?.defaults?.displayStart && defaults.defaults?.displayEnd) {
            // setAutoSubmit(true)
        } else {
            setShowPage(true)
        }

        // eslint-disable-next-line
    }, [])
    console.log(storedDataEdit?.defaults || legTransports?.defaults, legStart, legEnd)

    // useEffect(() => {
    //     if (autoSubmit) onSubmit()
    // }, [autoSubmit])

    useEffect(() => {
        if (startPlace?.name && endPlace.name && !storedDataEdit) onSubmit()
        // eslint-disable-next-line
    }, [startPlace?.name, endPlace.name])

    const onSubmit = async () => {
        let storedData = JSON.parse(localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData') || '{}')

        const { createLegPosition } = storedData

        if (storedData?.isOverallStart) {
            let commuteStartEndData = {
                startPlaceId: outboundData.startPlaceId,
                startPlaceName: outboundData.startPlaceName,
                startPlaceLon: outboundData.startPlaceLon,
                startPlaceLat: outboundData.startPlaceLat,
                endPlaceId: outboundData.endPlaceId,
                endPlaceName: outboundData.endPlaceName,
                endPlaceLon: outboundData.endPlaceLon,
                endPlaceLat: outboundData.endPlaceLat,
                returnJourneyStartPlaceId: returnData.startPlaceId,
                returnJourneyStartPlaceName: returnData.startPlaceName,
                returnJourneyStartPlaceLon: returnData.startPlaceLon,
                returnJourneyStartPlaceLat: returnData.startPlaceLat,
                returnJourneyEndPlaceId: returnData.endPlaceId,
                returnJourneyEndPlaceName: returnData.endPlaceName,
                returnJourneyEndPlaceLon: returnData.endPlaceLon,
                returnJourneyEndPlaceLat: returnData.endPlaceLat,
            }

            if (!goingBackCommute) {
                commuteStartEndData = {
                    ...commuteStartEndData,
                    startPlaceId: startPlace.placeId,
                    startPlaceName: startPlace.name,
                    startPlaceLon: startPlace.lon,
                    startPlaceLat: startPlace.lat,
                }
            }

            if (goingBackCommute) {
                commuteStartEndData = {
                    ...commuteStartEndData,
                    returnJourneyStartPlaceId: startPlace.placeId,
                    returnJourneyStartPlaceName: startPlace.name,
                    returnJourneyStartPlaceLon: startPlace.lon,
                    returnJourneyStartPlaceLat: startPlace.lat,
                }
            }

            dispatch(changeCommuteDestination(outboundData, outboundData.backendCommuteId || commuteStartEnd.id, commuteStartEndData, () => {
            }))
        }

        if (storedData?.isOverallEnd) {
            let commuteStartEndData = {
                startPlaceId: outboundData.startPlaceId,
                startPlaceName: outboundData.startPlaceName,
                startPlaceLon: outboundData.startPlaceLon,
                startPlaceLat: outboundData.startPlaceLat,
                endPlaceId: outboundData.endPlaceId,
                endPlaceName: outboundData.endPlaceName,
                endPlaceLon: outboundData.endPlaceLon,
                endPlaceLat: outboundData.endPlaceLat,
                returnJourneyStartPlaceId: returnData.startPlaceId,
                returnJourneyStartPlaceName: returnData.startPlaceName,
                returnJourneyStartPlaceLon: returnData.startPlaceLon,
                returnJourneyStartPlaceLat: returnData.startPlaceLat,
                returnJourneyEndPlaceId: returnData.endPlaceId,
                returnJourneyEndPlaceName: returnData.endPlaceName,
                returnJourneyEndPlaceLon: returnData.endPlaceLon,
                returnJourneyEndPlaceLat: returnData.endPlaceLat,
            }

            if (!goingBackCommute) {
                commuteStartEndData = {
                    ...commuteStartEndData,
                    endPlaceId: endPlace.placeId,
                    endPlaceName: endPlace.name,
                    endPlaceLon: endPlace.lon,
                    endPlaceLat: endPlace.lat,
                }
            }

            if (goingBackCommute) {
                commuteStartEndData = {
                    ...commuteStartEndData,
                    returnJourneyEndPlaceId: endPlace.placeId,
                    returnJourneyEndPlaceName: endPlace.name,
                    returnJourneyEndPlaceLon: endPlace.lon,
                    returnJourneyEndPlaceLat: endPlace.lat,
                }
            }

            dispatch(changeCommuteDestination(outboundData, outboundData.backendCommuteId || commuteStartEnd.id, commuteStartEndData))
        }

        setLoading(true)

        storedData = JSON.parse(localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData') || '{}')

        const newLeg: ILegs = {
            transportId: choosedCar?.id,
            transportName: choosedCar?.name,
            transportSize: sizeCar,
            transportFuelType: fuel,
            countOfPeople,
            isGoingBack: goingBackCommute,
            choosedCar,

            startPlaceId: startPlace.placeId,
            startPlaceName: startPlace.name,
            startPlaceLon: startPlace.lon,
            startPlaceLat: startPlace.lat,
            endPlaceId: endPlace.placeId,
            endPlaceName: endPlace.name,
            endPlaceLon: endPlace.lon,
            endPlaceLat: endPlace.lat,
            position: storedDataEdit?.position || createLegPosition || ((storedData.legs?.length || 0) + 1),

            backendLegId: storedDataEdit?.backendLegId || legTransports?.id,
            travelDistanceKilometres: 0,
            ceo2Kg: 0,
            defaults: storedDataEdit?.defaults || legTransports.defaults,
        }

        const gameEndLocations = [
            { placeId: game?.additionalPlace1Id, name: game?.additionalPlace1 },
            { placeId: game?.additionalPlace2Id, name: game?.additionalPlace2 },
            { placeId: game?.additionalPlace3Id, name: game?.additionalPlace3 },
        ]

        let travelEndLocation: any = null
        let sortedLegs = storedData.legs || []

        if (!edit) {
            sortedLegs = sortedLegs?.map((i: any, index: number) => ({ ...i, position: index + 1 }))

            sortedLegs = sortedLegs.map((i: any, index: number) => ({
                ...i,
                position: createLegPosition ? (i.position < createLegPosition ? i.position : i.position + 1) : index + 1,
            }))

            sortedLegs.push(newLeg)
            const gameEnd = gameEndLocations.find((i) => i.placeId === endPlace.placeId && !!i.name)

            if (endPlace.placeId && gameEnd) {
                const locationData = await getLocationByPlaceId(gameEnd.placeId)

                travelEndLocation = {
                    travelData: {
                        endPlaceId: gameEnd.placeId,
                        endPlaceName: gameEnd.name,
                        endPlaceLon: locationData.lon,
                        endPlaceLat: locationData.lat,
                    },
                    returnTravelData: {
                        startPlaceId: gameEnd.placeId,
                        startPlaceName: gameEnd.name,
                        startPlaceLon: locationData.lon,
                        startPlaceLat: locationData.lat,
                    },
                }
            }

            if (goingBackCommute) {
                localStorage.setItem('returnTravelData', JSON.stringify({ ...storedData, legs: sortedLegs }))
            }

            if (!goingBackCommute) {
                localStorage.setItem('travelData', JSON.stringify({ ...storedData, legs: sortedLegs, ...(travelEndLocation ? travelEndLocation.travelData : {}) }))
            }
        }

        let previousLeg = [...sortedLegs].sort((a, b) => b.position - a.position).filter((i) => i.position < (newLeg.position || 0))[0]
        let nextLeg = [...sortedLegs].sort((a, b) => a.position - b.position).filter((i) => i.position > (newLeg.position || 0))[0]

        if (previousLeg?.endPlaceName) {
            previousLeg = undefined
        }

        if (nextLeg?.startPlaceName) {
            nextLeg = undefined
        }

        const legStartEndData = {
            leg: storedDataEdit?.backendLegId || legTransports?.id,
            startPlaceId: startPlace?.placeId,
            startPlaceName: startPlace.name,
            startPlaceLon: startPlace.lon,
            startPlaceLat: startPlace.lat,

            endPlaceId: endPlace.placeId,
            endPlaceName: endPlace.name,
            endPlaceLon: endPlace.lon,
            endPlaceLat: endPlace.lat,
            previousLeg: previousLeg ? previousLeg?.backendLegId : undefined,
            nextLeg: nextLeg ? nextLeg?.backendLegId : undefined,
        }

        let newLegs = sortedLegs.map((i: any) => ({
            ...i,
            startPlaceId: nextLeg?.backendLegId === i.backendLegId ? legStartEndData.endPlaceId : i.startPlaceId,
            startPlaceName: nextLeg?.backendLegId === i.backendLegId ? legStartEndData.endPlaceName : i.startPlaceName,
            startPlaceLon: nextLeg?.backendLegId === i.backendLegId ? legStartEndData.endPlaceLon : i.startPlaceLon,
            startPlaceLat: nextLeg?.backendLegId === i.backendLegId ? legStartEndData.endPlaceLat : i.startPlaceLat,

            endPlaceId: previousLeg?.backendLegId === i.backendLegId ? legStartEndData.startPlaceId : i.endPlaceId,
            endPlaceName: previousLeg?.backendLegId === i.backendLegId ? legStartEndData.startPlaceName : i.endPlaceName,
            endPlaceLon: previousLeg?.backendLegId === i.backendLegId ? legStartEndData.startPlaceLon : i.endPlaceLon,
            endPlaceLat: previousLeg?.backendLegId === i.backendLegId ? legStartEndData.startPlaceLat : i.endPlaceLat,
        }))

        if (edit) {
            newLegs = sortedLegs.map((i: ILegs) => {
                if (i?.backendLegId === storedDataEdit?.backendLegId) return newLeg
                return i
            })
        }

        storedData = JSON.parse(localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData') || '{}')
        localStorage.setItem(goingBackCommute ? 'returnTravelData' : 'travelData', JSON.stringify({ ...storedData, legs: newLegs, createLegPosition: 0, legStartEndEmpty: false, legStartEnd: null, isOverallStart: false, isOverallEnd: false }))

        if (edit) localStorage.removeItem('travelDataEdit')

        let createLeg = {
            create: false,
            transport: storedDataEdit?.transportId,

            startPlaceId: '',
            startPlaceName: '',
            startPlaceLat: '',
            startPlaceLon: '',

            endPlaceId: '',
            endPlaceName: '',
            endPlaceLat: '',
            endPlaceLon: '',

            transportFuelType: storedDataEdit?.transportFuelType?.id || undefined,
            transportSize: storedDataEdit?.transportSize?.id || undefined,
            countOfPeople: storedDataEdit?.countOfPeople || undefined,

            commute: initTravelData?.backendCommuteId || commuteStartEnd.id,
            position: 1,
        }

        if (storedData.createStartLeg && legStartEndData.startPlaceName === storedDataEdit.startPlaceName) {
            createLeg = {
                ...createLeg,
                startPlaceId: legStartEndData.endPlaceId,
                startPlaceName: legStartEndData.endPlaceName,
                startPlaceLat: legStartEndData.endPlaceLat,
                startPlaceLon: legStartEndData.endPlaceLon,
                endPlaceId: storedDataEdit.endPlaceId,
                endPlaceName: storedDataEdit.endPlaceName,
                endPlaceLat: storedDataEdit.endPlaceLat,
                endPlaceLon: storedDataEdit.endPlaceLon,
                position: (newLeg.position || 0) + 1,
                create: true,
            }
        }

        if (storedData.createEndLeg && legStartEndData.endPlaceName === storedDataEdit.endPlaceName) {
            createLeg = {
                ...createLeg,
                startPlaceId: storedDataEdit.startPlaceId,
                startPlaceName: storedDataEdit.startPlaceName,
                startPlaceLat: storedDataEdit.startPlaceLat,
                startPlaceLon: storedDataEdit.startPlaceLon,
                endPlaceId: legStartEndData.startPlaceId,
                endPlaceName: legStartEndData.startPlaceName,
                endPlaceLat: legStartEndData.startPlaceLat,
                endPlaceLon: legStartEndData.startPlaceLon,
                position: ((newLeg.position || 1) - 1) || 1,
                create: true,
            }
        }

        dispatch(changeLegStartEnd(legStartEndData, (legStartEndResponse) => {
            changeLegFromLocalstorage(goingBackCommute, (i) => ({
                ...i,
                travelDistanceKilometres: legStartEndResponse.travelDistanceKilometres,
                ceo2Kg: legStartEndResponse.ceo2Kg,
            }), legStartEndResponse.id)

            if (createLeg.create) {
                dispatch(
                    editTravelCommute({
                        legs: [createLeg],
                        commute: initTravelData?.backendCommuteId || commuteStartEnd.id,
                    }, () => {
                        dispatch(clearLegTransport())
                        redirectWhenSuccess(createLegPosition, legStartEndResponse, travelEndLocation)
                    }),
                )
                return
            }

            dispatch(clearLegTransport())
            redirectWhenSuccess(createLegPosition, legStartEndResponse, travelEndLocation)
        }))
    }

    const redirectWhenSuccess = (createLegPosition: any, legStartEndResponse: any, travelEndLocation: any) => {
        if (!createLegPosition && legStartEndResponse?.nextLegDefaults?.multiDaySection?.startLocation && legStartEndResponse?.nextLegDefaults?.multiDaySection?.endLocation === 'return' && !legStartEndResponse?.nextLegDefaults?.multiDaySection?.endPlaceName && !travelEndLocation && !impact && !fromImpact) {
            navigate('/log/last-multi-day-section')
            setLoading(false)
            return
        }

        if (!createLegPosition && legStartEndResponse.nextLegDefaults.moreLegs && !travelEndLocation && !impact && !fromImpact) {
            navigate('/log/track-travel-modality', { state: { goingBackCommute } })
            setLoading(false)
            return
        }

        if (fromImpact) {
            navigate('/log/track-travel-overview', { state: { oneWay: false } })
            return
        }

        navigate('/log/track-travel-overview', { state: { impact, fuel, sizeCar, choosedCar, edit, goingBackCommute, prevUrl: location?.state?.prevUrl } })
    }

    const onBack = () => {
        if (fromImpact) {
            navigate(-1)
            return
        }

        if (carType) {
            navigate('/log/log-travel-car-details', { state: { impact, fuel, sizeCar, choosedCar, byCar, edit, goingBackCommute, countOfPeopleBack: countOfPeople, prevUrl: location?.state?.prevUrl, sizeTypeDatas, fuelTypeDatas, autoForward: true } })
            return
        }

        navigate('/log/track-travel-modality', { state: { impact, edit, goingBackCommute, allModeBack, prevUrl: location?.state?.prevUrl } })
    }

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    // IF AUTOSUBMIT IS TURNED ON, TURN THIS ON
    if (!showPage) {
        if (fuel && sizeCar) return <LogTravelCarDetails disableSelecting />
        return <TrackTravelModality disableSelecting />
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded}>
            <div className="w-full h-screen">
                <div style={{ marginTop: navbarHeight, left: '50%', transform: 'translateX(-50%)' }}
                    className="fixed top-0 left-0 w-full z-10 bg-white max-w-[1440px]">
                    {sponsoredBlock && <div className="mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                    <div className="flex justify-between items-center h-[53px] pr-2 mobile:mx-[42px] mx-4">
                        <div onClick={onBack} className="flex justify-end cursor-pointer">
                            <ArrowTravel width={22} height={22} />
                        </div>

                        <div onClick={() => setShowQuizQuitOverlayModal(true)}
                            className="flex justify-end cursor-pointer">
                            <DismissIcon width={18} height={18} />
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: screenWidth > 768 ? navbarHeight : !sponsoredBlock ? navbarHeight : 125 }} className="h-screen flex flex-col items-center mobile:px-[42px] px-4">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        <h1 className="heading-uppercase heading-font text-22 mt-2 text-cavolo tracking-tight font-semibold">
                            {t('log.journey-leg-details')}
                        </h1>

                        <p className="text-14 text-grey01 font-normal mt-[7px]">
                            {legDefaultsHelpText(legStartEnd?.nextLegDefaults?.multiDaySection || travelAccommodation?.legsDefaultData?.multiDaySection || commuteStartEnd.legDefaultsData?.multiDaySection, t)} {legStart} {legStartType} {t('global.to')} {legEnd} {legEndType}
                        </p>

                        <h1 className="text-cavolo text-16 tracking-tight font-medium not-heading-font mt-4">
                            {t(`log.${travelDate(today) >= minDate ? '' : 'FUTURE|'}where-did-you-getting-on`)} {transportVerbData?.verbFrom || t('log.start')}?
                        </h1>

                        <p className="text-12 text-grey01 font-normal mt-[7px] mb-3">
                            {t('log.the-name-of-the-station-or-stop,-or-an-address')}
                        </p>

                        {/* <GoogleAutoComplete places={transportMode?.places} placeholder={t('log.start-typing')} setPlace={setStartPlace} defaultValue={startPlace} /> */}
                        {isEmpty(savedPlaces) && user?.company?.showSavedPlaces ? (
                            <div className="flex items-center mb-2.5">
                                <DefaultHeartIcon />

                                <h1 className="ml-2.5 text-12 text-grey01">
                                    {t('savedPlaces.tap-the-heart-to-save-a-place-for-future-use')}
                                </h1>
                            </div>
                        ) : null}

                        <GoogleAutoComplete onChange={() => {
                            if (endRef.current) {
                                endRef.current?.focus()
                            }
                        }} places={transportMode?.places} placeholder={t('log.start-typing')} setPlace={setStartPlace} defaultValue={startPlace} />

                        <h1 className="text-cavolo text-16 tracking-tight not-heading-font font-medium mt-5">
                            {t(`log.${travelDate(today) >= minDate ? '' : 'FUTURE|'}where-did-you-getting-off`)} {transportVerbData?.verbTo || t('log.end')}?
                        </h1>

                        <p className="text-12 text-grey01 font-normal mt-[7px] mb-3">
                            {t(`log.${travelDate(today) >= minDate ? '' : 'FUTURE|'}don't-worry-about-any-places-you-changed-vehicles-but-remained-travelling-by-the-same-mode-of-transport`)}
                        </p>

                        <GoogleAutoComplete selectRef={endRef} places={transportMode?.places}
                            placeholder={t('log.start-typing')} setPlace={setEndPlace}
                            defaultValue={endPlace} />
                    </div>

                    {/* {(startPlace?.name === initTravelData?.startPlaceName && endPlace?.name === initTravelData?.endPlaceName) && ( */}
                    <div
                        className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                        style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                        <div
                            className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">

                            <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                            <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                                <Button
                                    loading={loading}
                                    onClick={onSubmit}
                                    style={loading || !startPlace.name || !endPlace.name ? disabledStyles : undefined}
                                    disabled={loading || !startPlace.placeId || !endPlace.placeId}
                                    className="absolute bottom-8 duration-100 not-heading-font"
                                    text={t('global.continue')} />
                            </div>
                        </div>
                    </div>
                    {/* )} */}

                    <QuizQuitOverlayModal
                        description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                        isShown={showQuizQuitOverlayModal}
                        onClose={() => navigate(location?.state?.prevUrl || '/')}
                        onContinue={() => setShowQuizQuitOverlayModal(false)} />
                </div>
            </div>
        </Layout>
    )
}
