import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { articles } from '../../state/articles/selectors'
import { getDateTime } from '../../utils/date'
import Skeleton from '../common/Skeleton'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'
import { getUser } from '../../state/user/selectors'

export default function InPartnershipDiscover() {
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { articleHomePage } = useSelector(articles)
    const [t] = useTranslation('global')

    const navigateArticleDetail = (articleId: number | string) => {
        navigate(`/learn/articles/${articleId}`)
    }
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    const article = articleHomePage.promotedArticle

    if (!article) return <div />

    let extractedNumber: number | null = null

    if (typeof article?.getReadTime === 'string') {
        const matchResult = article?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    return (
        <div onClick={() => navigateArticleDetail(article.id)} className="w-full mb-8 xs-mobile:mt-8 font cursor-pointer" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="relative">
                <div className="bg-pistachio hidden tablet911:block w-[456px] rounded-lg absolute right-6 bottom-6">
                    <div className="p-5 flex flex-col">
                        <h4 className="font-light tracking-tight text-grey01 text-12">
                            {user?.company?.isDateStampShown && getDateTime(article?.createdAt)} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                        </h4>

                        <ContentBlockTitle text={article?.title} />
                        {/* <h2 className="mt-0.5 font-medium font tracking-tight text-black text-[22px]"> */}
                        {/*    {article?.title} */}
                        {/* </h2> */}

                        <p className="mt-1 font-light tracking-tight text-grey01 text-16">
                            {article?.subTitle}
                        </p>
                    </div>

                    {/* <div className="flex flex-col pb-3"> */}
                    {/*    <span className="border-t-2 border-lime" /> */}

                    {/*    <div className="flex px-5 pt-3 pb-3  flex-row justify-between"> */}
                    {/*        <h3 className="font-light tracking-tight text-grey01 text-16"> */}
                    {/*            {t('global.in-partnership-with')} */}
                    {/*        </h3> */}

                    {/*        <h3 className="text-black font-semibold text-18"> */}
                    {/*            {article?.sponsoredBy} */}
                    {/*        </h3> */}
                    {/*    </div> */}
                    {/* </div> */}
                </div>

                {loading && <Skeleton className="object-cover laptop:h-[560px] tablet:h-[460px] tablet911:h-[434px] mobile:h-[353px] h-410 rounded-t-lg tablet911:rounded-lg w-full" />}

                <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} object-cover laptop:h-[560px] tablet:h-[460px] tablet911:h-[434px] mobile:h-[353px] h-410 rounded-t-lg tablet911:rounded-lg w-full`} src={article?.image} alt="" />

                {/* bottom */}
                <div className="pb-4 rounded-b-lg block tablet911:hidden bg-pistachio">
                    {article?.sponsorshipBlock && <SponsoredByBlock noBorder noBackground item={article.sponsorshipBlock} />}

                    <p className="font-light px-4 pt-4 tracking-tight text-grey01 font text-12">
                        {user?.company?.isDateStampShown && getDateTime(article?.createdAt)} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                    </p>

                    <h5 className="mt-0.5 px-4 font-medium font tracking-tight text-black text-18">
                        {article?.title}
                    </h5>

                    <p className="mt-0.5 px-4 font-light tracking-tight text-grey01 text-14">
                        {article?.subTitle}
                    </p>

                    {/* <div className="my-4 bg-lime h-[4px]" /> */}

                    {/* <div className="flex items-center justify-between px-4"> */}
                    {/*    <span className="font-light tracking-tight text-grey01 text-14"> */}
                    {/*        {t('global.in-partnership-with')} */}
                    {/*    </span> */}

                    {/*    <span className="text-black font-semibold text-18"> */}
                    {/*        {article?.sponsoredBy} */}
                    {/*    </span> */}
                    {/* </div> */}
                </div>
            </div>

        </div>
    )
}
